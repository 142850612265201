<script>
import { WButton, WModal, WModalFullscreen } from '@WebiumTeam/ui_kit';

export const messages = {
  ru: {
    text: 'покинуть экскурсию?',
    description:
      'ты точно хочешь покинуть экскурсию? <br>заново начать ее уже не получится',
    leave: 'покинуть',
    back: 'назад',
  },
};

export default {
  i18n: { messages },
  components: {
    WButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modalComponent() {
      return this.$device.isSmallScreen ? WModalFullscreen : WModal;
    },
  },
};
</script>

<template>
  <component
    :is="modalComponent"
    :visible="visible"
    hide-close
    disable-overlay-close-event
    is-overlay-transparent
    @close="$emit('close')"
  >
    <div :class="$style.wrapper">
      <img
        :class="$style.image"
        :src="require('./images/img-modal-escape.svg')"
        width="336"
        height="238"
        alt=""
      />
      <p :class="$style.text">{{ $t('text') }}</p>
      <p :class="$style.description" v-html="$t('description')"></p>
    </div>
    <template #footerContent>
      <div :class="$style.footer">
        <WButton
          theme="secondary"
          :size="$device.isSmallScreen ? 'l' : 'm'"
          data-testid="leave-button"
          @click="$emit('leave')"
        >
          {{ $t('leave') }}
        </WButton>
        <WButton
          :size="$device.isSmallScreen ? 'l' : 'm'"
          data-testid="back-button"
          @click="$emit('close')"
          >{{ $t('back') }}
        </WButton>
      </div>
    </template>
  </component>
</template>

<style lang="postcss" module>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  width: 100%;
  height: auto;
  margin-bottom: 24px;
  border-radius: 12px;
}

.text {
  margin-bottom: 8px;
  text-align: center;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.48px;
}

.description {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.32px;
}

.footer {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
}

@media (--small-vp) {
  .image {
    width: auto;
    height: 238px;
    margin-bottom: 16px;
  }

  .text {
    margin-bottom: 16px;
  }

  .footer {
    justify-content: flex-start;

    & > button {
      flex: 1 1 0;
    }
  }
}
</style>
