// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notification-modal__menu-wt9oo{position:absolute;right:64px}.notification-modal__headerContent-6ty-L{display:flex;grid-gap:8px;gap:8px;align-items:center}.notification-modal__notificationList-lzrwR{position:relative}.notification-modal__notificationList-lzrwR:after{position:absolute;bottom:0;left:0;width:100%;height:2px;content:\"\";background:var(--grey-200)}@media (max-width:1023px){.notification-modal__notificationList-lzrwR{height:100%;margin:0 -18px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "notification-modal__menu-wt9oo",
	"headerContent": "notification-modal__headerContent-6ty-L",
	"notificationList": "notification-modal__notificationList-lzrwR"
};
module.exports = ___CSS_LOADER_EXPORT___;
