<script>
export const messages = {
  ru: {
    line1: 'перед тобой задание, которое нужно выполнить',
    line2: 'ты справишься!',
    line3: 'не забудь завершить домашку',
  },
};

export default {
  i18n: { messages },
};
</script>

<template>
  <div :class="$style.content">
    <p>
      {{ $t('line1') }}
    </p>
    <p>
      {{ $t('line2') }}
    </p>
    <p>
      {{ $t('line3') }}
    </p>
  </div>
</template>

<style lang="postcss" module>
.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.32px;
}
</style>
