export const EVENT_BUS_KEYS = {
  webinarWatched: 'webinar-watched',
  webinarPlay: 'webinar-play',
  homeworkCompleted: 'homework-completed',
  mentorRated: 'mentor-rated',
  lessonRated: 'lesson-rated',
  workoutTaskSolved: 'workout-task-solved',
  commonOnboardingFinished: 'common-onboarding-finished',
  serverDateUpdated: 'server-date-updated',
  buddyAdded: 'buddy-added',
  buddyRemoved: 'buddy-removed',
  prolongCoursesButtonClick: 'prolong-courses-button-click',
  pageMounted: 'page-mounted',
  streakPopoverShown: 'streak-popover-shown',
  streakPopoverClosed: 'streak-popover-closed',
  showTimelineMobileClick: 'show-timeline-mobile-click',
  freeCourseOnboardingABTestHappend: 'free-course-onboarding-ab-test-happend',
};
