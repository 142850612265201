// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".step-course-homeworks-timeline-mobile__content-FQfEN{display:flex;flex-direction:column;grid-gap:12px;gap:12px;align-items:flex-start;justify-content:flex-start;font-size:16px;line-height:20px;letter-spacing:.32px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "step-course-homeworks-timeline-mobile__content-FQfEN"
};
module.exports = ___CSS_LOADER_EXPORT___;
