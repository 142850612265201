<script>
import { WButton, WTooltip } from '@WebiumTeam/ui_kit';
import { createNamespacedHelpers } from 'vuex';
import { SYSTEM_NOTIFICATION_SLUGS } from '~/constants/system-notifications';
import { TOOLTIPS } from '~/constants/tooltips';

const { mapActions: mapTooltipsActions, mapGetters: mapTooltipsGetters } =
  createNamespacedHelpers('tooltips');

export default {
  components: {
    WButton,
    WTooltip,
  },
  props: {
    tooltipKey: {
      type: String,
      required: false,
      default: '',
      validate(value) {
        return Object.keys(TOOLTIPS).includes(value);
      },
    },
    systemNotificationSlug: {
      type: String,
      required: false,
      default: '',
      validate(value) {
        return Object.keys(SYSTEM_NOTIFICATION_SLUGS).includes(value);
      },
    },
    text: {
      type: String,
      default: '',
      required: false,
    },
    closeButtonText: {
      type: String,
      default: '',
      required: false,
    },
    placement: {
      type: String,
      default: 'bottom',
      required: false,
    },
    mobilePlacement: {
      type: String,
      default: '',
      required: false,
    },
    maxWidth: {
      type: String,
      default: '',
      required: false,
    },
    popperClass: {
      type: [String, Array],
      default: '',
      required: false,
    },
    hideOnClick: {
      type: Boolean,
      default: false,
    },
    hideOnRouteChange: {
      type: Boolean,
      default: false,
    },
    disabledAutoScroll: {
      type: Boolean,
      default: false,
    },
    delayForSmoothScroll: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapTooltipsGetters(['isTooltipShown']),
    isShown() {
      return (
        this.isTooltipShown(this.tooltipKey) || !!this.systemNotificationSlug
      );
    },
    currentPlacement() {
      return this.$device.isSmallScreen
        ? this.mobilePlacement || this.placement
        : this.placement;
    },
  },
  watch: {
    isShown(value) {
      if (value) {
        this.$nextTick(() => {
          if (!this.disabledAutoScroll) {
            this.scrollIntoView();
          }
        });
        if (this.hideOnRouteChange) {
          this.markAsHidden(this.tooltipKey);
        }
      }
    },
  },
  mounted() {
    if (!this.systemNotificationSlug) return;
    this.$systemNotifications.markSystemNotificationsAsViewed([
      this.systemNotificationSlug,
    ]);
  },
  beforeDestroy() {
    if (!this.hideOnRouteChange) return;
    this.hideSystemNotification();
    this.handleHide();
  },
  methods: {
    ...mapTooltipsActions(['hide', 'markAsHidden']),
    computePosition() {
      this.$refs.tooltip.computePosition();
    },
    scrollIntoView() {
      if (!this.$refs.target) return;
      setTimeout(() => {
        this.$refs.target.scrollIntoView({
          behavior: 'smooth',
          inline: 'center',
        });
      }, this.delayForSmoothScroll);
    },
    handleHide() {
      if (this.hideSystemNotification()) return;

      this.hide({
        key: this.tooltipKey,
        options: {
          hideForever: true,
        },
      });
    },
    hideSystemNotification() {
      if (!this.systemNotificationSlug) return false;
      this.$systemNotifications.hideSystemNotification(
        this.systemNotificationSlug
      );
      return true;
    },
    onClose() {
      this.handleHide();
      this.$emit('close');
    },
    onClick() {
      if (this.hideOnClick) {
        this.handleHide();
      }
    },
  },
};
</script>

<template>
  <WTooltip
    ref="tooltip"
    :triggers="[]"
    :shown="isShown"
    theme="attention"
    :popper-class="[$style.root, popperClass]"
    v-bind="$attrs"
    :overflow-padding="0"
    :placement="currentPlacement"
    v-on="$listeners"
  >
    <div ref="target">
      <slot></slot>
    </div>
    <template #text>
      <div
        :class="$style.inner"
        :style="{ maxWidth }"
        @click="onClick"
        @keyup.enter="onClick"
      >
        <slot name="text">
          <span v-html="text"></span>
          <WButton
            v-if="closeButtonText"
            :class="$style.closeButton"
            theme="white"
            :size="$device.isSmallScreen ? 's' : 'xs'"
            @click="onClose"
          >
            {{ closeButtonText }}
          </WButton>
        </slot>
      </div>
    </template>
  </WTooltip>
</template>

<style lang="postcss" module>
.inner {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
}

@media (--small-vp) {
  .root {
    padding: 0 12px;
  }
}
</style>
