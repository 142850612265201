<script>
export const messages = {
  ru: {
    line1: 'здесь всё, <br>что нужно для занятий:',
    line2: 'видеоуроки и вебинары',
    line3: 'домашки и пробники',
    line4: 'нажимай на видеоурок <br>и давай знакомиться!',
  },
};

export default {
  i18n: { messages },
};
</script>

<template>
  <div :class="$style.content">
    <p v-html="$t('line1')"></p>
    <div :class="$style.textWrapper">
      <p>
        <img
          :src="require('./icon-webinar.svg')"
          width="24"
          height="24"
          alt=""
        />
        {{ $t('line2') }}
      </p>
      <p>
        <img
          :src="require('./icon-homework.svg')"
          width="24"
          height="24"
          alt=""
        />
        {{ $t('line3') }}
      </p>
    </div>
    <p v-html="$t('line4')"></p>
  </div>
</template>

<style lang="postcss" module>
.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.32px;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.28px;

  & p {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}
</style>
