// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".free-course-onboarding-modal-finish__wrapper-Zq8rc{display:flex;flex-direction:column}.free-course-onboarding-modal-finish__image-icWxA{position:relative;display:flex;align-items:center;justify-content:center;width:100%;margin-bottom:12px}.free-course-onboarding-modal-finish__background-TYPWk{width:100%;height:auto;border-radius:12px}.free-course-onboarding-modal-finish__avatar-\\+FE1b{position:absolute;top:14px;left:50%;transform:translateX(-50%)}.free-course-onboarding-modal-finish__text-61fJF{margin-bottom:12px;font-size:24px;line-height:28px}.free-course-onboarding-modal-finish__description-t-8aX{font-size:16px;line-height:20px}.free-course-onboarding-modal-finish__description-t-8aX:not(:last-child){margin-bottom:8px}.free-course-onboarding-modal-finish__xpSign-d54Ea{margin-left:4px;margin-top:-4px}.free-course-onboarding-modal-finish__achievementImages-YsJv1{display:flex;grid-gap:16px;gap:16px;width:100%}.free-course-onboarding-modal-finish__achievementImages-YsJv1 img{width:157px;height:auto}.free-course-onboarding-modal-finish__footer-LLje-{display:flex;grid-gap:16px;gap:16px;justify-content:flex-end;width:100%}@media (max-width:1023px){.free-course-onboarding-modal-finish__description-t-8aX:not(:last-child){margin-bottom:12px}.free-course-onboarding-modal-finish__achievementImages-YsJv1 img{width:160px}.free-course-onboarding-modal-finish__footer-LLje-{justify-content:flex-start}.free-course-onboarding-modal-finish__footer-LLje->a,.free-course-onboarding-modal-finish__footer-LLje->button{flex:1 1 0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "free-course-onboarding-modal-finish__wrapper-Zq8rc",
	"image": "free-course-onboarding-modal-finish__image-icWxA",
	"background": "free-course-onboarding-modal-finish__background-TYPWk",
	"avatar": "free-course-onboarding-modal-finish__avatar-+FE1b",
	"text": "free-course-onboarding-modal-finish__text-61fJF",
	"description": "free-course-onboarding-modal-finish__description-t-8aX",
	"xpSign": "free-course-onboarding-modal-finish__xpSign-d54Ea",
	"achievementImages": "free-course-onboarding-modal-finish__achievementImages-YsJv1",
	"footer": "free-course-onboarding-modal-finish__footer-LLje-"
};
module.exports = ___CSS_LOADER_EXPORT___;
