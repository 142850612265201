// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notification-settings-modal__footer-fNT50{display:flex;grid-gap:16px;gap:16px;align-items:center;justify-content:flex-end}@media (max-width:1023px){.notification-settings-modal__footer-fNT50{justify-content:flex-start;height:-moz-fit-content;height:fit-content}.notification-settings-modal__footer-fNT50>button{flex-grow:1}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "notification-settings-modal__footer-fNT50"
};
module.exports = ___CSS_LOADER_EXPORT___;
