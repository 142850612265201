<script>
import capitalize from 'lodash/capitalize';
import { createNamespacedHelpers } from 'vuex';
import { isFreeCourseOnboardingActive } from '~/utils/free-course-onboarding';
import XpSign from '~/components/xp-sign.vue';

const { mapState } = createNamespacedHelpers('motivation');

export const messages = {
  ru: {
    labelTemplate: '+ {0}',
  },
};

const hideTimeout = 3000;

export default {
  i18n: {
    messages,
  },
  components: { XpSign },
  props: {
    position: {
      type: String,
      default: 'right',
      validate(value) {
        return ['right', 'bottom'].includes(value);
      },
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    ...mapState(['sumGotXp', 'lastGotXp']),
    classes() {
      return {
        [this.$style.root]: true,
        [this.$style[`_${this.position}`]]: !!this.position,
      };
    },
    transitionName() {
      return `xpTransition${capitalize(this.position)}`;
    },
    xpSignColor() {
      return 'green';
    },
  },
  watch: {
    sumGotXp() {
      const isHideAllPopups = isFreeCourseOnboardingActive();
      if (isHideAllPopups) return;
      this.isVisible = true;
      setTimeout(() => {
        this.isVisible = false;
      }, hideTimeout);
    },
  },
};
</script>

<template>
  <Transition :name="transitionName">
    <span v-if="isVisible" :class="classes" data-testid="root">
      <XpSign size="l" :color="xpSignColor">
        {{ $t('labelTemplate', [lastGotXp]) }}
      </XpSign>
    </span>
  </Transition>
</template>

<!-- eslint-disable vue-scoped-css/enforce-style-type-->
<style>
.xpTransitionBottom-enter-active,
.xpTransitionBottom-leave-active,
.xpTransitionRight-enter-active,
.xpTransitionRight-leave-active {
  transition: all 0.5s ease;
}

.xpTransitionBottom-leave-to,
.xpTransitionRight-leave-to {
  opacity: 0;
}

.xpTransitionBottom-enter {
  margin-top: -10px;
  opacity: 0;
}

.xpTransitionRight-enter {
  margin-left: -10px;
  opacity: 0;
}
</style>

<style lang="postcss" module>
.root {
  --background-color: var(--primary-100);
  --text-color: var(--primary-500);

  position: absolute;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: fit-content;
  min-width: 62px;
  height: 32px;
  padding: 0 10px;
  font-size: 16px;
  line-height: 1;
  color: var(--text-color);
  white-space: nowrap;
  background-color: var(--background-color);
  border-radius: 8px;
  box-shadow: var(--card-shadow);

  &._right {
    --arrow-url: url('arrow-default.svg');

    top: 50%;
    left: 100%;
    transform: translateX(13px) translateY(-50%);

    &::before {
      --width: 5px;

      position: absolute;
      left: calc(-1 * var(--width));
      width: var(--width);
      height: 32px;
      content: '';
      background-image: var(--arrow-url);
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &._bottom {
    --arrow-url: url('arrow-default-up.svg');
    --_indicator-top-offset: 10px;

    top: calc(100% + var(--_indicator-top-offset));
    right: 6px;
    left: initial;

    &::before {
      --height: 5px;

      position: absolute;
      top: calc(-1 * var(--height));
      width: 100%;
      height: var(--height);
      content: '';
      background-image: var(--arrow-url);
      background-repeat: no-repeat;
    }
  }
}
</style>
