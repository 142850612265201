// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal-delimiter__root-P64fB{--size:2px;--color:var(--grey-200);position:relative;left:-100vw;display:block;width:300vw;height:var(--size);margin:0;padding:0;border:0;background:var(--color)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "modal-delimiter__root-P64fB"
};
module.exports = ___CSS_LOADER_EXPORT___;
