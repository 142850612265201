<script>
import { createNamespacedHelpers } from 'vuex';
import { isFreeCourseOnboardingActive } from '~/utils/free-course-onboarding';
import BaseToastMessage from '~/components/_base/_base-toast-message.vue';
import MotivationInfo from '~/components/motivation/motivation-info.vue';

const { mapState } = createNamespacedHelpers('motivation');

const defaultImage = require('~/components/motivation/level-default.svg');

export const messages = {
  ru: {
    title: 'теперь ты ещё круче!',
    description: 'поздравляем с переходом на новый уровень',
  },
};

export default {
  i18n: {
    messages,
  },
  components: {
    MotivationInfo,
    BaseToastMessage,
  },
  computed: {
    ...mapState(['hasNewLevel', 'lastFetchedLevel']),
    levelImage() {
      return this.lastFetchedLevel?.image || defaultImage;
    },
  },
  watch: {
    hasNewLevel: {
      handler(value) {
        const isHideAllPopups = isFreeCourseOnboardingActive();
        if (isHideAllPopups) return;
        if (value) {
          this.$refs.message?.show();
        }
      },
    },
  },
};
</script>

<template>
  <Portal to="toast-messages">
    <BaseToastMessage ref="message" auto-hide>
      <MotivationInfo
        :class="$style.info"
        :description="$t('description')"
        :title="$t('title')"
        :image="levelImage"
      />
    </BaseToastMessage>
  </Portal>
</template>

<style lang="postcss" module>
.info {
  --motivation-image-width: 82px;
  --motivation-image-height: 82px;
  --motivation-info-padding: 6px 46px 6px 12px;
  --motivation-title-font-size: 18px;
  --motivation-title-line-height: 22px;
}

@media (--small-vp) {
  .info {
    --motivation-image-width: 72px;
    --motivation-image-height: 72px;
    --motivation-info-padding: 6px 28px 6px 12px;
    --motivation-title-font-size: 16px;
    --motivation-title-line-height: 20px;
  }
}
</style>
