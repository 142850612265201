import qs from 'qs';

/* eslint-disable no-param-reassign */
function useCommaArrayFormat(config) {
  config.paramsSerializer = (params) =>
    qs.stringify(params, { arrayFormat: 'repeat' });
}

function useCustomHeaders(config) {
  config.headers.common['X-User-Timezone-Offset'] =
    new Date().getTimezoneOffset();
  config.headers.common['X-Is-Tab-Active'] = process.client
    ? !document.hidden
    : false;
}
/* eslint-enable no-param-reassign */

export default ({ $axios, store }) => {
  $axios.onRequest((config) => {
    useCommaArrayFormat(config);
    useCustomHeaders(config);
    return config;
  });

  $axios.onResponse((response) => {
    const { date } = response.headers;
    if (date) {
      store.dispatch('base/setServerDate', date);
    }
  });
};
