export function setSessionStorageItem(key, value) {
  window.sessionStorage.setItem(key, JSON.stringify(value));
}

export function getSessionStorageItem(key) {
  const item = window.sessionStorage.getItem(key);
  return JSON.parse(item);
}

export function removeSessionStorageItem(key) {
  window.sessionStorage.removeItem(key);
}
