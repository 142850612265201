export const FREE_COURSE_ONBOARDING_STEPS = {
  startOnboarding: 'startOnboarding',
  courseWebinarMarkCompleted: 'courseWebinarMarkCompleted',
  courseSchedule: 'courseSchedule',
  courseWebinars: 'courseWebinars',
  courseStreaks: 'courseStreaks',
  courseHomeworks: 'courseHomeworks',
  courseHomeworksTimelineMobile: 'courseHomeworksTimelineMobile',
  courseTask: 'courseTask',
  courseContacts: 'courseContacts',
  finishOnboarding: 'finishOnboarding',
};

export const FREE_COURSE_BACKLIGHT_ELEMENTS_DATA_ATTR_VALUES = {
  courseContentBlock: 'course-content-block',
  courseContentBlockWebinar: 'course-content-block-webinar',
  courseContentBlockHomework: 'course-content-block-homework',
  courseContentBlockWebinarIcon: 'course-content-block-webinar-icon',
  courseContentBlockHomeworkIcon: 'course-content-block-homework-icon',
  courseWebinarPlayer: 'course-webinar-player',
  courseWebinarMarkAsCompleted: 'course-webinar-mark-as-completed',
  streaksCounterButton: 'streaks-counter-button',
  streaksPopper: 'streaks-popper',
  streaksPopperCloseButton: 'streaks-popper-close-button',
  scheduleBlock: 'course-schedule-block',
  mobileScheduleButton: 'mobile-schedule-button',
  taskDescription: 'task-description',
  taskAnswer: 'task-answer',
  tasksList: 'tasks-list',
  tasksListItem: 'tasks-list-item',
  courseHomeworkPanel: 'course-homework-panel',
  courseInfoList: 'course-info-list',
  courseMobileMenu: 'course-mobile-menu',
  courseMobileMenuContacts: 'course-mobile-menu-contacts',
};

export const FREE_COURSE_BACKLIGHT_ELEMENTS_CLASSES = {
  onboardingActive: 'free-course-onboarding-active',
  onboardingBacklight: 'free-course-onboarding-backlight',
  onboardingBacklightWithBlockedContent:
    'free-course-onboarding-backlight-with-blocked-content',
  onboardingBacklightAccentElement:
    'free-course-onboarding-backlight-accent-element',
  onboardingWaveMedium: 'free-course-onboarding-wave-medium',
  onboardingWaveLarge: 'free-course-onboarding-wave-large',
};
