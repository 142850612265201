<script>
import { WButton, WDrawer, WModal } from '@WebiumTeam/ui_kit';
import kebabCase from 'lodash/kebabCase';
import { createNamespacedHelpers } from 'vuex';
import { ONBOARDING_STEPS } from '~/constants/onboarding';
import BaseSegmentedProgress from '~/components/_base/_base-segmented-progress.vue';
import ModalDelimiter from '~/components/modal-delimiter.vue';

const { mapState, mapGetters, mapActions } =
  createNamespacedHelpers('common-onboarding');

const cachedStepComponents = {};

export const messages = {
  ru: {
    skip: 'пропустить',
    progressLabels: {
      [ONBOARDING_STEPS.vkConnect]: 'подключи уведомления',
      [ONBOARDING_STEPS.notifications]: 'подключи уведомления',
      [ONBOARDING_STEPS.survey]: 'выбери стиль обучения',
    },
  },
};

export default {
  i18n: {
    messages,
  },
  components: {
    ModalDelimiter,
    BaseSegmentedProgress,
    WButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['currentStepGlobalIndex']),
    ...mapGetters([
      'onboardingSegments',
      'currentStepIndex',
      'currentStepKey',
      'nextStepKey',
      'hasNextAvailableStep',
      'isAllStepsCompleted',
      'skippedSegmentIndexes',
    ]),
    modalComponent() {
      return this.$device.isSmallScreen ? WDrawer : WModal;
    },
    progressSegments() {
      return this.onboardingSegments.map((segment) => ({
        label: this.$t(`progressLabels.${segment[0].key}`),
        value: segment.length,
      }));
    },
    currentStepComponent() {
      if (cachedStepComponents[this.currentStepKey])
        return cachedStepComponents[this.currentStepKey];
      const componentName = `step-${kebabCase(this.currentStepKey)}`;
      cachedStepComponents[this.currentStepKey] = () =>
        import(`./steps/${componentName}.vue`);
      return cachedStepComponents[this.currentStepKey];
    },
  },
  methods: {
    ...mapActions(['goToNextStep', 'goToPreviousStep']),
    onNextStep() {
      if (this.isAllStepsCompleted) {
        this.$emit('complete');
      } else if (this.hasNextAvailableStep) {
        this.goToNextStep();
        this.$emit('step-change', this.currentStepKey);
      } else {
        this.$emit('close');
      }
    },
    onPreviousStep() {
      this.goToPreviousStep();
      this.$emit('step-change', this.currentStepKey);
    },
  },
};
</script>

<template>
  <component
    :is="modalComponent"
    size="m"
    rounded
    hide-header
    hide-close
    side="bottom"
    :visible="visible"
    :class="$style.root"
  >
    <div :class="$style.inner">
      <div :class="$style.header">
        <BaseSegmentedProgress
          :class="$style.segmentedProgress"
          :skipped-segments="skippedSegmentIndexes"
          :segments="progressSegments"
          :value="currentStepGlobalIndex"
          :size="$device.isSmallScreen ? 's' : 'm'"
          highlight-current-segment
        />
      </div>
      <ModalDelimiter />
      <div :class="$style.body">
        <component
          :is="currentStepComponent"
          v-if="currentStepKey"
          :has-next-available-step="hasNextAvailableStep"
          :current-step-index="currentStepIndex"
          :next-step-key="nextStepKey"
          @next-step="onNextStep"
          @previous-step="onPreviousStep"
        />
      </div>
    </div>
    <WButton
      :class="$style.skipButton"
      size="xs"
      theme="transparent"
      data-testid="do-later-button"
      @click="onNextStep"
    >
      {{ $t('skip') }}
    </WButton>
  </component>
</template>

<style lang="postcss" module>
.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  padding-bottom: 24px;
}

.body {
  padding: 24px 0 0;
}

.skipButton {
  position: absolute;
  top: calc(100% + 16px);
  right: 0;
}

.segmentedProgress {
  --segments-gap: 12px;
  --label-font-size: 16px;
  --label-line-height: 20px;

  height: 56px;
  padding: 8px 0 12px;
}

@media (--small-vp) {
  .root {
    --w-drawer-height: calc(100% - 62px);
  }

  .segmentedProgress {
    --segments-gap: 16px;
    --label-font-size: 14px;
    --label-line-height: 18px;
  }

  .inner {
    max-width: 100%;
    overflow: hidden;
  }

  .header {
    padding: 12px 20px 8px;
  }

  .body {
    padding: 20px 20px 12px;
  }

  .skipButton {
    position: fixed;
    top: 13px;
    right: 13px;
  }
}
</style>
