<script>
export default {
  props: {
    size: {
      type: String,
      default: 's',
      validate: (value) => ['xs', 's', 'm', 'l'].includes(value),
    },
    segments: {
      type: Array,
      required: true,
      validate(value) {
        return value.every((item) => {
          if (typeof item === 'object') {
            return (
              typeof item.value === 'number' && typeof item.label === 'string'
            );
          }
          return typeof item === 'number';
        });
      },
    },
    value: {
      type: Number,
      default: 0,
    },
    highlightCurrentSegment: {
      type: Boolean,
      default: false,
    },
    skippedSegments: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    rootClasses() {
      return {
        [this.$style.root]: true,
        [this.$style[`_${this.size}`]]: !!this.size,
      };
    },
    currentSegmentIndex() {
      const segmentValues = this.segments.map((item) => item.value || item);
      let segmentCounter = 0;
      let currentSegmentIndex;
      segmentValues.forEach((value, index) => {
        if (currentSegmentIndex !== undefined) return;
        segmentCounter += value;
        if (segmentCounter >= this.value + 1) {
          currentSegmentIndex = index;
        }
      });
      return currentSegmentIndex;
    },
  },
  methods: {
    getSegmentCompletePercent(segmentIndex) {
      if ((this.skippedSegments || []).includes(segmentIndex)) {
        return 0;
      }
      const segmentValues = this.segments.map((item) => item.value || item);
      const previousStepsValue = segmentValues
        .slice(0, segmentIndex)
        .reduce((sum, current) => sum + current, 0);
      const currentStepValue = this.value - previousStepsValue;
      return currentStepValue >= segmentValues[segmentIndex]
        ? 100
        : (currentStepValue / segmentValues[segmentIndex]) * 100;
    },
    getStepStyle(segmentIndex) {
      const segmentPercentComplete =
        this.getSegmentCompletePercent(segmentIndex);
      return {
        width: `${segmentPercentComplete}%`,
      };
    },
    getSegmentWrapperClasses(segmentIndex) {
      const segmentPercentComplete =
        this.getSegmentCompletePercent(segmentIndex);
      return {
        [this.$style.segmentWrapper]: true,
        [this.$style._completed]: segmentPercentComplete >= 100,
        [this.$style._current]:
          this.highlightCurrentSegment &&
          this.currentSegmentIndex === segmentIndex,
      };
    },
  },
};
</script>

<template>
  <div :class="rootClasses">
    <div
      v-for="(segment, segmentIndex) in segments"
      :key="segmentIndex"
      :class="getSegmentWrapperClasses(segmentIndex)"
    >
      <span v-if="segment.label" :class="$style.label">
        {{ segment.label }}
      </span>
      <div :class="$style.segment" data-testid="segment">
        <span
          :class="$style.step"
          :style="getStepStyle(segmentIndex)"
          data-testid="step"
        ></span>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" module>
.root {
  --segments-gap: 6px;
  --border-radius: 4px;
  --label-font-size: 16px;
  --label-line-height: 20px;
  --completed-color: var(--success-500);

  display: flex;
  gap: var(--segments-gap);
  width: 100%;

  &._xs {
    --height: 2px;
  }

  &._s,
  &._m {
    --height: 4px;
  }

  &._l {
    --height: 6px;
  }
}

.segment {
  position: relative;
  display: flex;
  flex-grow: 1;
  height: var(--height);
  max-height: var(--height);
  background-color: var(--grey-300);
  border-radius: var(--border-radius);
}

.label {
  font-size: var(--label-font-size);
  line-height: var(--label-line-height);
  text-align: center;
  color: var(--grey-600);
}

.step {
  display: block;
  height: inherit;
  border-radius: inherit;
  background-color: var(--completed-color);
  transition: all 0.3s ease-in;
}

.segmentWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;

  &._current {
    & .label {
      color: var(--primary-500);
    }

    & .segment {
      background-color: var(--primary-100);
      box-shadow: 0 0 0 2px var(--primary-500);
    }
  }

  &._completed {
    & .label {
      color: var(--completed-color);
    }
  }
}
</style>
