// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".step-course-schedule__content-HmGge{display:flex;flex-direction:column;grid-gap:12px;gap:12px;align-items:flex-start;justify-content:flex-start;font-size:16px;line-height:20px;letter-spacing:.32px}.step-course-schedule__textWrapper-FSW7c{flex-direction:column;font-size:14px;line-height:18px;letter-spacing:.28px}.step-course-schedule__textWrapper-FSW7c,.step-course-schedule__textWrapper-FSW7c p{display:flex;grid-gap:8px;gap:8px}.step-course-schedule__textWrapper-FSW7c p{align-items:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "step-course-schedule__content-HmGge",
	"textWrapper": "step-course-schedule__textWrapper-FSW7c"
};
module.exports = ___CSS_LOADER_EXPORT___;
