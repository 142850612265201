// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".free-course-onboarding-tooltip__root-3AvNZ{position:absolute;z-index:3000000000;display:flex;grid-gap:26px;gap:26px;align-items:flex-end}.free-course-onboarding-tooltip__wrapper-oekdo{position:relative;display:flex;flex-direction:column;width:254px;border-radius:8px;background-color:var(--primary-100)}.free-course-onboarding-tooltip__arrow-GwklH{position:absolute;bottom:0;left:-22px;width:24px;height:auto}.free-course-onboarding-tooltip__avatar-BjlMs{flex-shrink:0;width:72px;height:72px;border-radius:50%}.free-course-onboarding-tooltip__avatar-BjlMs img{width:100%;height:100%;-o-object-fit:cover;object-fit:cover;border-radius:50%}.free-course-onboarding-tooltip__header-QK79q{display:flex;align-items:center;justify-content:space-between;padding:4px 4px 4px 20px;color:var(--grey-600);font-size:16px;line-height:20px;letter-spacing:.32px}.free-course-onboarding-tooltip__content-3nRUn{display:flex;flex-direction:column;grid-gap:16px;gap:16px;align-items:flex-start;justify-content:flex-start;padding:12px 20px 18px}@media (max-width:374px){.free-course-onboarding-tooltip__wrapper-oekdo{width:238px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "free-course-onboarding-tooltip__root-3AvNZ",
	"wrapper": "free-course-onboarding-tooltip__wrapper-oekdo",
	"arrow": "free-course-onboarding-tooltip__arrow-GwklH",
	"avatar": "free-course-onboarding-tooltip__avatar-BjlMs",
	"header": "free-course-onboarding-tooltip__header-QK79q",
	"content": "free-course-onboarding-tooltip__content-3nRUn"
};
module.exports = ___CSS_LOADER_EXPORT___;
