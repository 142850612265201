<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
  <div :class="$style.root">
    <div v-hide-if-empty :class="$style.infoWrapper">
      <span v-if="name" :class="$style.name">{{ name }}</span>
      <p v-if="description" :class="$style.description">{{ description }}</p>
    </div>
    <div :class="$style.content">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="postcss" module>
.root {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.infoWrapper {
  display: flex;
  flex-direction: column;
}

.name {
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.description {
  font-size: 14px;
  line-height: 18px;
  color: var(--grey-600);
}

.content {
  display: flex;
  flex-shrink: 0;
  gap: 38px;
  width: 100%;
}

@media (--small-vp) {
  .root {
    align-items: flex-start;
  }

  .content {
    gap: 24px;
  }
}
</style>
