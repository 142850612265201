// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".free-course-onboarding-manager__root-XHMNQ{position:absolute;z-index:3000000001}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "free-course-onboarding-manager__root-XHMNQ"
};
module.exports = ___CSS_LOADER_EXPORT___;
