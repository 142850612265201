/* eslint-disable sonarjs/no-duplicate-string */
import {
  FREE_COURSE_ONBOARDING_STEPS,
  FREE_COURSE_BACKLIGHT_ELEMENTS_CLASSES as elementClasses,
  FREE_COURSE_BACKLIGHT_ELEMENTS_DATA_ATTR_VALUES as elementDataAttributeValues,
} from '~/constants/free-course-onboarding';
import { LAST_ACTIVE_STEP_ID_SESSION_KEY } from '~/constants/session-storage';
import { gtmEvents, gtmPushEvent } from '~/services/gtm/gtm';
import {
  getSessionStorageItem,
  removeSessionStorageItem,
  setSessionStorageItem,
} from '~/services/session-storage';

const setClassForAccentElements = (accentElements) => {
  if (!accentElements || accentElements.length === 0) return false;
  let processedElement = 0;
  accentElements.forEach((activeItem) => {
    const activeElement = document.querySelector(
      `[data-free-course-block-key="${activeItem.attrValue}"]`
    );
    if (activeElement) {
      activeElement.classList.add(
        elementClasses.onboardingBacklightAccentElement
      );
      processedElement += 1;
    }
  });

  return !!processedElement;
};

const setClassForWave = (props) => {
  if (!props) return;
  const element = document.querySelector(
    `[data-free-course-block-key="${props.attrValue}"]`
  );
  if (!element) return;
  element.classList.add(
    props.size === 'medium'
      ? elementClasses.onboardingWaveMedium
      : elementClasses.onboardingWaveLarge
  );
};

const setClassForBacklightElements = (backlightElements) => {
  if (!backlightElements || backlightElements.length === 0) return;

  backlightElements.forEach((backlightItem) => {
    const backlightElement = document.querySelector(
      `[data-free-course-block-key="${backlightItem.attrValue}"]`
    );
    if (!backlightElement) return;
    backlightElement.classList.add(elementClasses.onboardingBacklight);

    const isElementForAccentExist = setClassForAccentElements(
      backlightItem.accentElements
    );
    if (isElementForAccentExist)
      backlightElement.classList.add(
        elementClasses.onboardingBacklightWithBlockedContent
      );
    setClassForWave(backlightItem.wave);
  });
};

const removeClasses = (classes) => {
  classes.forEach((classForRemove) => {
    const elements = document.querySelectorAll(`.${classForRemove}`);
    elements.forEach((item) => {
      item.classList.remove(classForRemove);
    });
  });
};

const removeAccentClasses = () => {
  const classesForRemove = [
    elementClasses.onboardingBacklight,
    elementClasses.onboardingBacklightWithBlockedContent,
    elementClasses.onboardingBacklightAccentElement,
  ];
  removeClasses(classesForRemove);
};

const removeWaveClasses = () => {
  const classesForRemove = [
    elementClasses.onboardingWaveMedium,
    elementClasses.onboardingWaveLarge,
  ];
  removeClasses(classesForRemove);
};

const removeOverlay = () => {
  const body = document.querySelector('body');
  body.classList.remove(elementClasses.onboardingActive);
};

const generateTooltipPosition = ({ parent, tooltip, positions }) => {
  const tooltipMargin = 16;
  const actualPositionKey = positions.find((item) => tooltip?.[item]);
  if (!actualPositionKey) return undefined;

  const position = tooltip[actualPositionKey];
  if (!position) return undefined;
  const tooltipElement = document.querySelector(
    '#free-course-onboarding-tooltip'
  );

  const parentPositionX = parent.getBoundingClientRect().x;
  const parentPositionY = parent.getBoundingClientRect().y + window.scrollY;

  const result = {};
  switch (position) {
    case 'left-start':
      result.left =
        parentPositionX - tooltipElement.offsetWidth - tooltipMargin;
      result.top = parentPositionY;
      break;
    case 'left-end':
      result.left =
        parentPositionX - tooltipElement.offsetWidth - tooltipMargin;
      result.top =
        parentPositionY - tooltipElement.offsetHeight + parent.offsetHeight;
      break;
    case 'top-start':
      result.left = parentPositionX;
      result.top =
        parentPositionY - tooltipElement.offsetHeight - tooltipMargin;
      break;
    case 'right-end':
      result.left = parentPositionX + parent.offsetWidth + tooltipMargin;
      result.top =
        parentPositionY - tooltipElement.offsetHeight + parent.offsetHeight;
      break;
    case 'right-start':
      result.left = parentPositionX + parent.offsetWidth + tooltipMargin;
      result.top = parentPositionY;
      break;
    case 'bottom-end':
      result.left =
        parentPositionX + parent.offsetWidth - tooltipElement.offsetWidth;
      result.top = parentPositionY + parent.offsetHeight + tooltipMargin;
      break;
    case 'bottom-start':
      result.left = parentPositionX;
      result.top = parentPositionY + parent.offsetHeight + tooltipMargin;
      break;
    default:
  }

  return result;
};

export const state = () => ({
  activeStepId: undefined,
  tooltipPosition: undefined,
  isEscapeOnboardingModalVisible: false,
  steps: [
    {
      stepProgressIndex: 0,
      id: FREE_COURSE_ONBOARDING_STEPS.startOnboarding,
    },
    {
      stepProgressIndex: 1,
      id: FREE_COURSE_ONBOARDING_STEPS.courseSchedule,
      backlightElements: [
        {
          attrValue: elementDataAttributeValues.courseContentBlock,
          accentElements: [
            {
              attrValue: elementDataAttributeValues.courseContentBlockWebinar,
            },
          ],
          tooltip: {
            position: 'left-start',
            smallScreenPosition: 'top-start',
          },
          wave: {
            size: 'medium',
            attrValue: elementDataAttributeValues.courseContentBlockWebinarIcon,
          },
        },
      ],
    },
    {
      stepProgressIndex: 2,
      id: FREE_COURSE_ONBOARDING_STEPS.courseWebinars,
      backlightElements: [
        {
          attrValue: elementDataAttributeValues.courseWebinarMarkAsCompleted,
          tooltip: {
            smallScreenPosition: 'bottom-start',
          },
        },
        {
          attrValue: elementDataAttributeValues.courseWebinarPlayer,
          tooltip: {
            position: 'bottom-start',
          },
          wave: {
            size: 'large',
            attrValue: elementDataAttributeValues.courseWebinarPlayer,
          },
        },
      ],
    },
    {
      stepProgressIndex: 3,
      id: FREE_COURSE_ONBOARDING_STEPS.courseStreaks,
      backlightElements: [
        {
          attrValue: elementDataAttributeValues.streaksCounterButton,
        },
        {
          attrValue: elementDataAttributeValues.streaksPopper,
          tooltip: {
            position: 'left-end',
            smallScreenPosition: 'bottom-start',
          },
          wave: {
            size: 'medium',
            attrValue: elementDataAttributeValues.streaksPopperCloseButton,
          },
        },
      ],
    },
    {
      stepProgressIndex: 4,
      id: FREE_COURSE_ONBOARDING_STEPS.courseHomeworks,
      backlightElements: [
        {
          attrValue: elementDataAttributeValues.mobileScheduleButton,
          tooltip: {
            smallScreenPosition: 'bottom-end',
            notLargeScreenPosition: 'bottom-end',
          },
          wave: {
            size: 'medium',
            attrValue: elementDataAttributeValues.mobileScheduleButton,
          },
        },
        {
          attrValue: elementDataAttributeValues.scheduleBlock,
          accentElements: [
            {
              attrValue: elementDataAttributeValues.courseContentBlockHomework,
            },
          ],
          tooltip: {
            position: 'left-start',
          },
          wave: {
            size: 'medium',
            attrValue:
              elementDataAttributeValues.courseContentBlockHomeworkIcon,
          },
        },
      ],
    },
    {
      stepProgressIndex: 4,
      id: FREE_COURSE_ONBOARDING_STEPS.courseHomeworksTimelineMobile,
      backlightElements: [
        {
          attrValue: elementDataAttributeValues.courseContentBlockHomework,
          tooltip: {
            position: 'bottom-end',
            smallScreenPosition: 'bottom-end',
          },
          wave: {
            size: 'medium',
            attrValue:
              elementDataAttributeValues.courseContentBlockHomeworkIcon,
          },
        },
      ],
    },
    {
      stepProgressIndex: 5,
      id: FREE_COURSE_ONBOARDING_STEPS.courseTask,
      backlightElements: [
        {
          attrValue: elementDataAttributeValues.taskDescription,
        },
        {
          attrValue: elementDataAttributeValues.taskAnswer,
        },
        {
          attrValue: elementDataAttributeValues.tasksList,
        },
        {
          attrValue: elementDataAttributeValues.courseHomeworkPanel,
        },
        {
          attrValue: elementDataAttributeValues.tasksListItem,
          tooltip: {
            position: 'right-end',
            smallScreenPosition: 'top-start',
          },
        },
      ],
    },
    {
      stepProgressIndex: 6,
      id: FREE_COURSE_ONBOARDING_STEPS.courseContacts,
      backlightElements: [
        {
          attrValue: elementDataAttributeValues.courseInfoList,
          tooltip: {
            position: 'right-start',
          },
        },
        {
          attrValue: elementDataAttributeValues.courseMobileMenu,
          accentElements: [
            {
              attrValue: elementDataAttributeValues.courseMobileMenuContacts,
            },
          ],
          tooltip: {
            smallScreenPosition: 'top-start',
          },
        },
      ],
    },
    {
      stepProgressIndex: 7,
      id: FREE_COURSE_ONBOARDING_STEPS.finishOnboarding,
    },
  ],
});

export const mutations = {
  SET_ACTIVE_STEP_ID(state, value) {
    state.activeStepId = value;
    if (value !== FREE_COURSE_ONBOARDING_STEPS.courseHomeworksTimelineMobile)
      setSessionStorageItem(LAST_ACTIVE_STEP_ID_SESSION_KEY, value);
  },
  SET_TOOLTIP_POSITION(state, value) {
    state.tooltipPosition = value;
  },
  SET_IS_ESCAPE_ONBOARDING_MODAL_VISIBLE(state, value) {
    state.isEscapeOnboardingModalVisible = !!value;
  },
};

export const getters = {
  activeStep(state) {
    if (!state.activeStepId) return undefined;
    return state.steps.find((item) => item.id === state.activeStepId);
  },
  activeStepProgressIndex(state, getters) {
    if (!getters.activeStep) return 0;
    return getters.activeStep.stepProgressIndex;
  },
  actualTooltipPositionKeys(state, getters, rootState, rootGetters) {
    const isSmallScreen = rootGetters['responsive/isSmallScreen'];
    const isLargeScreen = rootGetters['responsive/isLargeScreen'];

    const validPositions = [];
    if (isSmallScreen) validPositions.push('smallScreenPosition');
    if (!isLargeScreen) validPositions.push('notLargeScreenPosition');
    if (!isSmallScreen) validPositions.push('position');

    return validPositions;
  },
  activeStepWithTooltip(state, getters) {
    if (!getters.activeStep) return undefined;
    return getters.activeStep.backlightElements?.find((item) => {
      const actualPosition = getters.actualTooltipPositionKeys.find(
        (position) => item.tooltip?.[position]
      );
      return item.tooltip?.[actualPosition];
    });
  },
  isTooltipVisible(state) {
    return (
      !state.isEscapeOnboardingModalVisible &&
      state.activeStepId !== FREE_COURSE_ONBOARDING_STEPS.finishOnboarding &&
      state.activeStepId !== FREE_COURSE_ONBOARDING_STEPS.startOnboarding
    );
  },
};

export const actions = {
  init({ state, commit, dispatch }) {
    const lastActiveStepId = getSessionStorageItem(
      LAST_ACTIVE_STEP_ID_SESSION_KEY
    );
    commit('SET_ACTIVE_STEP_ID', lastActiveStepId || state.steps[0].id);
    setTimeout(() => dispatch('applyActiveStep'));
    dispatch('trackActiveStep');

    window.addEventListener('resize', () => {
      dispatch('updateTooltipPosition');
    });
  },
  switchEscapeOnboardingModalVisibility({ getters, commit }, value) {
    if (value) {
      removeAccentClasses();
      removeWaveClasses();
      gtmPushEvent(gtmEvents.onboardingShowEscapeMod);
    } else {
      setClassForBacklightElements(getters.activeStep.backlightElements);
    }
    commit('SET_IS_ESCAPE_ONBOARDING_MODAL_VISIBLE', !!value);
  },
  closeOnboarding({ commit }) {
    commit('SET_ACTIVE_STEP_ID', undefined);
    commit('SET_IS_ESCAPE_ONBOARDING_MODAL_VISIBLE', false);
    removeSessionStorageItem(LAST_ACTIVE_STEP_ID_SESSION_KEY);
    removeAccentClasses();
    removeWaveClasses();
    removeOverlay();
  },
  switchStep({ commit, dispatch }, stepId) {
    if (!FREE_COURSE_ONBOARDING_STEPS[stepId]) return;
    commit('SET_ACTIVE_STEP_ID', stepId);
    setTimeout(() => dispatch('applyActiveStep'));
    dispatch('trackActiveStep');
  },
  applyActiveStep({ getters, dispatch }) {
    if (!getters.activeStep) return;
    const body = document.querySelector('body');
    body.classList.add(elementClasses.onboardingActive);

    removeAccentClasses();
    removeWaveClasses();
    setClassForBacklightElements(getters.activeStep.backlightElements);
    dispatch('updateTooltipPosition');
  },
  updateTooltipPosition({ state, commit, getters }) {
    if (!state.activeStepId) return;
    if (!getters.activeStepWithTooltip) return;

    const backlightElement = document.querySelector(
      `[data-free-course-block-key="${getters.activeStepWithTooltip.attrValue}"]`
    );
    if (!backlightElement) return;
    const tooltipPosition = generateTooltipPosition({
      parent: backlightElement,
      tooltip: getters.activeStepWithTooltip.tooltip,
      positions: getters.actualTooltipPositionKeys,
    });
    if (!tooltipPosition) return;
    commit('SET_TOOLTIP_POSITION', tooltipPosition);
  },
  trackActiveStep({ getters }) {
    gtmPushEvent(gtmEvents.onboardingShowStep, {
      onboarding_step_number: getters.activeStepProgressIndex,
    });
  },
  removeWaveClasses() {
    removeWaveClasses();
  },
};
