<script>
import { WButton, WModal, WModalSheet } from '@WebiumTeam/ui_kit';
import XpSign from '~/components/xp-sign.vue';

export const messages = {
  ru: {
    text: 'отличная работа!',
    xpDescription: 'держи заслуженные ачивки и ',
    achievementsDescription:
      'ачивки и награды, которые ты будешь получать за учёбу можно посмотреть в разделе «достижения»',
    inAchievements: 'в достижения',
    thanks: 'ура! спасибо',
  },
};

export default {
  i18n: { messages },
  components: {
    WButton,
    XpSign,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    course: {
      type: Object,
      required: true,
    },
  },
  computed: {
    modalComponent() {
      return this.$device.isSmallScreen ? WModalSheet : WModal;
    },
    teacherAvatar() {
      return this.course.teachers[0].avatar;
    },
  },
  beforeDestroy() {
    this.$emit('close');
  },
};
</script>

<template>
  <component
    :is="modalComponent"
    :visible="visible"
    is-overlay-transparent
    @close="$emit('close')"
  >
    <div :class="$style.wrapper">
      <div :class="$style.image">
        <img
          :class="$style.background"
          :src="require('./images/img-modal-finish-background.svg')"
          width="336"
          height="205"
          alt=""
        />
        <img
          :class="$style.avatar"
          :src="teacherAvatar"
          width="82"
          height="82"
          alt=""
          data-testid="teacher-avatar"
        />
      </div>
      <p :class="$style.text">
        {{ $t('text') }}
      </p>
      <p :class="$style.description">
        {{ $t('xpDescription') }}
        <XpSign :class="$style.xpSign" size="l" text="+200" />
      </p>
      <p :class="$style.description" v-html="$t('achievementsDescription')"></p>
      <div :class="$style.achievementImages">
        <img
          :src="require('./images/achievement-web.svg')"
          width="157"
          height="196"
          alt=""
        />
        <img
          :src="require('./images/achievement-homework.svg')"
          width="157"
          height="196"
          alt=""
        />
      </div>
    </div>
    <template #footerContent>
      <div :class="$style.footer">
        <WButton
          tag="NuxtLink"
          :to="{
            name: 'achievements',
          }"
          theme="secondary"
          :size="$device.isSmallScreen ? 'l' : 'm'"
          data-testid="achievements-button"
        >
          {{ $t('inAchievements') }}
        </WButton>
        <WButton
          :size="$device.isSmallScreen ? 'l' : 'm'"
          data-testid="thanks-button"
          @click="$emit('close')"
          >{{ $t('thanks') }}
        </WButton>
      </div>
    </template>
  </component>
</template>

<style lang="postcss" module>
.wrapper {
  display: flex;
  flex-direction: column;
}

.image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 12px;
}

.background {
  width: 100%;
  height: auto;
  border-radius: 12px;
}

.avatar {
  position: absolute;
  top: 14px;
  left: 50%;
  transform: translateX(-50%);
}

.text {
  margin-bottom: 12px;
  font-size: 24px;
  line-height: 28px;
}

.description {
  font-size: 16px;
  line-height: 20px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.xpSign {
  margin-left: 4px;
  margin-top: -4px;
}

.achievementImages {
  display: flex;
  gap: 16px;
  width: 100%;

  & img {
    width: 157px;
    height: auto;
  }
}

.footer {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  width: 100%;
}

@media (--small-vp) {
  .description {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  .achievementImages {
    & img {
      width: 160px;
    }
  }

  .footer {
    justify-content: flex-start;

    & > button,
    & > a {
      flex: 1 1 0;
    }
  }
}
</style>
