import Vue from 'vue';
import {
  getCourseProgressKimNumber,
  getCourseProgressKimNumbersTasks,
  getCourseProgressSubTheme,
  getCourseProgressSubThemeTasks,
  getCourseProgressTheme,
  getCourseProgressThemeTasks,
} from '~/api/workout';

const successFilterName = 'success';
const invalidFilterName = 'invalid';
export const validTasksFilters = new Set([
  successFilterName,
  invalidFilterName,
]);

export const state = () => ({
  loading: false,
  activeTasksFilter: undefined,
  progressTasks: [],
  workoutItem: undefined,
});

const generateSolutionResult = (tasks) =>
  tasks
    .filter((task) => task.solution)
    .map((task) => ({
      ...task.correctAnswers,
      id: task.id,
      solution: { ...task.solution },
      maxScore: task.maxScore,
      hintsForSolution: task.hintsForSolution,
    }));

export const mutations = {
  SET_ACTIVE_TASKS_FILTER(state, value) {
    if (validTasksFilters.has(value)) state.activeTasksFilter = value;
  },
  RESET_ACTIVE_TASKS_FILTER(state) {
    state.activeTasksFilter = undefined;
  },
  SET_PROGRESS_TASKS(state, tasks) {
    state.progressTasks = tasks;
  },
  UPDATE_PROGRESS_TASK(state, task) {
    const taskIndex = state.progressTasks.findIndex(
      (item) => item.id === task.id
    );
    if (taskIndex === -1) return;
    const oldTask = state.progressTasks[taskIndex];
    if (task.solution && task.solution.score !== oldTask.solution?.score) {
      Vue.set(state.progressTasks, taskIndex, {
        ...task,
        oldSolution: oldTask.oldSolution || oldTask.solution,
      });
    } else {
      Vue.set(state.progressTasks, taskIndex, task);
    }
  },
  RESET_PROGRESS_TASKS_OLD_SOLUTIONS(state) {
    state.progressTasks = state.progressTasks.map(
      ({ oldSolution, ...keepAttributes }) => keepAttributes
    );
  },
  SET_LOADING(state, value) {
    state.loading = !!value;
  },
  SET_WORKOUT_ITEM(state, value) {
    state.workoutItem = value;
  },
  RESET_WORKOUT_ITEM(state) {
    state.workoutItem = undefined;
  },
};

export const actions = {
  updateWorkoutItemScore({ state, commit }, { score }) {
    const newWorkoutItem = { ...state.workoutItem };
    newWorkoutItem.userScore += score;
    newWorkoutItem.prevWeekUserScore += score;

    commit('SET_WORKOUT_ITEM', newWorkoutItem);
  },
  async loadProgressTasks({ state, commit }, params = {}) {
    if ((!params.themeId && !params.topicKimNumberId) || state.loading) return;
    commit('SET_LOADING', true);

    let requestMethod = params.subThemeId
      ? getCourseProgressSubThemeTasks
      : getCourseProgressThemeTasks;
    if (params.topicKimNumberId) {
      requestMethod = getCourseProgressKimNumbersTasks;
    }

    const tasks = await requestMethod(params);

    commit('SET_LOADING', false);
    commit('SET_PROGRESS_TASKS', tasks);
  },
  updateProgressTask({ commit }, task) {
    commit('UPDATE_PROGRESS_TASK', task);
  },
  setActiveTasksFilter({ commit }, value) {
    commit('SET_ACTIVE_TASKS_FILTER', value);
    commit('RESET_PROGRESS_TASKS_OLD_SOLUTIONS');
  },
  resetActiveTasksFilter({ commit }) {
    commit('RESET_ACTIVE_TASKS_FILTER');
    commit('RESET_PROGRESS_TASKS_OLD_SOLUTIONS');
  },
  setLoading({ commit }, value) {
    commit('SET_LOADING', value);
  },
  async loadCourseProgressTheme({ commit }, params) {
    if (state.loading) return;
    commit('RESET_WORKOUT_ITEM');
    commit('SET_LOADING', true);

    const courseProgressTheme = await getCourseProgressTheme(params);

    commit('SET_LOADING', false);
    commit('SET_WORKOUT_ITEM', courseProgressTheme);
  },
  async loadCourseProgressSubTheme({ commit }, params) {
    if (state.loading) return;
    commit('RESET_WORKOUT_ITEM');
    commit('SET_LOADING', true);

    const courseProgressSubTheme = await getCourseProgressSubTheme(params);

    commit('SET_LOADING', false);
    commit('SET_WORKOUT_ITEM', courseProgressSubTheme);
  },
  async loadCourseProgressKimNumber({ commit }, params) {
    if (state.loading) return;
    commit('RESET_WORKOUT_ITEM');
    commit('SET_LOADING', true);

    const courseProgressKimNumber = await getCourseProgressKimNumber(params);

    commit('SET_LOADING', false);
    commit('SET_WORKOUT_ITEM', courseProgressKimNumber);
  },
};

export const getters = {
  progressTasksSolutionStats: (state, getters) => ({
    tasksCount: state.progressTasks.length,
    solutionsCount: getters.progressTasksSolutionResults.length,
    rightSolutionsCount: getters.progressTasksWithRightSolution.length,
    invalidSolutionsCount: getters.progressTasksWithInvalidSolution.length,
  }),
  // eslint-disable-next-line sonarjs/cognitive-complexity
  progressTasksSelectedList(state, getters) {
    if (getters.isSuccessTasksFilterActive)
      return getters.progressTasksWithRightSolution;
    if (getters.isInvalidTasksFilterActive)
      return state.progressTasks
        .filter((task) => task.solution)
        .filter((task) =>
          Object.prototype.hasOwnProperty.call(task, 'oldSolution')
            ? task.maxScore !== task.oldSolution.score &&
              task.oldSolution?.isReviewed !== false
            : task.maxScore !== task.solution.score &&
              task.solution?.isReviewed !== false
        );

    const tasks = state.progressTasks.filter((task) => {
      const solution = Object.prototype.hasOwnProperty.call(task, 'oldSolution')
        ? task.oldSolution
        : task.solution;

      return (
        !solution || (task.taskType === 'free-answer' && !solution.isReviewed)
      );
    });

    return tasks.sort((task) =>
      task.requiresSelfReview &&
      task.solution &&
      (!Object.prototype.hasOwnProperty.call(task, 'oldSolution') ||
        (task.solution?.isReviewed && !task.oldSolution.task))
        ? -1
        : 1
    );
  },
  progressTasksWithInvalidSolution: (state) =>
    state.progressTasks
      .filter((task) => task.solution)
      .filter(
        (task) =>
          task.maxScore !== task.solution.score &&
          task.solution?.isReviewed !== false
      ),
  progressTasksWithRightSolution: (state) =>
    state.progressTasks
      .filter((task) => task.solution)
      .filter((task) => task.maxScore === task.solution.score),
  progressTasksSolutionResults(state) {
    return generateSolutionResult(state.progressTasks);
  },
  progressSelectedTasksSolutionResults(state, getters) {
    return generateSolutionResult(getters.progressTasksSelectedList);
  },
  isSuccessTasksFilterActive(state) {
    return state.activeTasksFilter === successFilterName;
  },
  isInvalidTasksFilterActive(state) {
    return state.activeTasksFilter === invalidFilterName;
  },
  workoutItemIsKimNumber(state) {
    return !!state.workoutItem?.topicKimNumber;
  },
  workoutItemIsSubTheme(state) {
    return !!state.workoutItem?.subTheme;
  },
};
