// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notification-settings__root-spFhw{--group-gap:24px;display:flex;flex-direction:column;height:inherit}.notification-settings__root-spFhw .notification-settings__loading-VAtKr{border-radius:8px}.notification-settings__group-GSYro{display:flex;flex-direction:column;grid-gap:var(--group-gap);gap:var(--group-gap);align-content:flex-start;padding:24px 0}.notification-settings__content-NYm-X{max-height:62vh}.notification-settings__connectButtons-XwPnq{display:flex;flex-wrap:wrap;grid-gap:40px;gap:40px;padding:8px 0 28px}.notification-settings__connectButtonWrapper-8-0uP{display:flex;flex-direction:column;grid-gap:12px;gap:12px}.notification-settings__connectButtonTitleWrapper-HevLj{position:relative;display:flex;grid-gap:8px;gap:8px}.notification-settings__connectButtonTitleHint-CImVJ{position:absolute;right:-30px;top:-2px}.notification-settings__connectButtonPopper-uc9i3{--max-width:248px}@media (max-width:1023px){.notification-settings__group-GSYro{--group-gap:32px}.notification-settings__content-NYm-X{max-height:100%}.notification-settings__connectButtons-XwPnq{grid-gap:20px;gap:20px;padding:24px 0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "notification-settings__root-spFhw",
	"loading": "notification-settings__loading-VAtKr",
	"group": "notification-settings__group-GSYro",
	"content": "notification-settings__content-NYm-X",
	"connectButtons": "notification-settings__connectButtons-XwPnq",
	"connectButtonWrapper": "notification-settings__connectButtonWrapper-8-0uP",
	"connectButtonTitleWrapper": "notification-settings__connectButtonTitleWrapper-HevLj",
	"connectButtonTitleHint": "notification-settings__connectButtonTitleHint-CImVJ",
	"connectButtonPopper": "notification-settings__connectButtonPopper-uc9i3"
};
module.exports = ___CSS_LOADER_EXPORT___;
