<script>
import { WButton, WModal, WModalSheet } from '@WebiumTeam/ui_kit';
import { setSurveysGreetingAnswer } from '~/api/surveys';
import BaseRadiobuttonGroup from '~/components/_base/_base-radiobutton-group.vue';
import BasePopover from '~/components/_base/popover/_base-popover.vue';
import ModalDelimiter from '~/components/modal-delimiter.vue';

export const messages = {
  ru: {
    submit: 'отправить',
    later: 'позже',
    examType: 'какой экзамен ты планируешь сдавать?',
    examYear: 'в каком году будешь сдавать экзамен?',
    friendsStudied: 'учился ли кто-нибудь из твоих друзей/знакомых в Вебиуме?',
    yes: 'да',
    no: 'нет',

    undecided: 'не определился',
    fromYoutube: 'Youtube, Shorts',
    fromvkAds: 'реклама во Вконтакте',
    fromvkSearch: 'поиск по группам ВК',
    fromGoogle: 'поисковые системы (Yandex, Google и др.)',
    fromParents: 'от родителей',
    recommendSchool: 'посоветовали вашу школу',
    fromInstagram: 'Instagram, Reels',
    fromTikTok: 'TikTok',
    fromWebsite: 'сайт «Без Сменки»',
    fromTelegram: 'Telegram',
    fromArticle: 'статья в интернете',
    other: 'другое',
    popoverTitle: 'как ты о нас узнал?',
  },
};

export default {
  components: {
    ModalDelimiter,
    WButton,
    BasePopover,
    BaseRadiobuttonGroup,
  },
  i18n: { messages },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      examYear: '',
      examType: '',
      hasFriendsInWebium: '',
      joinReason: '',
    };
  },
  computed: {
    modalComponent() {
      return this.$device.isSmallScreen ? WModalSheet : WModal;
    },
    examTypeList() {
      return [
        { label: 'ЕГЭ', value: 'ege' },
        { label: 'ОГЭ', value: 'oge' },
      ];
    },
    examYearList() {
      return [
        { label: '2024', value: '2024' },
        { label: '2025', value: '2025' },
        { label: '2026', value: '2026' },
        { label: this.$t('undecided'), value: false },
      ];
    },
    hasFriendsInWebiumList() {
      return [
        {
          label: this.$t('yes'),
          value: true,
          stopPropagation: true,
        },
        {
          label: this.$t('no'),
          value: false,
        },
      ];
    },
    joinReasonItems() {
      return [
        { label: this.$t('fromYoutube') },
        { label: this.$t('fromvkAds') },
        { label: this.$t('fromParents') },
        { label: this.$t('recommendSchool') },
        { label: this.$t('fromTelegram') },
        { label: this.$t('fromTikTok') },
        { label: this.$t('fromGoogle') },
        { label: this.$t('fromvkSearch') },
        { label: this.$t('fromInstagram') },
        { label: this.$t('fromArticle') },
        { label: this.$t('fromWebsite') },
        { label: this.$t('other') },
      ];
    },
    isFormValid() {
      return (
        this.examType &&
        (this.examYear || this.examYear === false) &&
        (this.hasFriendsInWebium === true || this.joinReason)
      );
    },
  },
  watch: {
    hasFriendsInWebium(value) {
      if (value === true) this.joinReason = '';
    },
  },
  methods: {
    onSubmit() {
      if (!this.isFormValid) return;
      const requestData = {
        isClosed: false,
        hasFriendsInWebium: this.hasFriendsInWebium,
        joinReason: this.joinReason,
        examType: this.examType,
      };
      if (this.examYear) requestData.examYear = this.examYear;
      setSurveysGreetingAnswer(requestData);
      this.$emit('close');
    },
    onClose() {
      setSurveysGreetingAnswer({
        isClosed: true,
      });
      this.$emit('close');
    },
  },
};
</script>

<template>
  <component :is="modalComponent" :visible="visible" size="m" @close="onClose">
    <div :class="$style.content">
      <form :class="$style.form" @submit.prevent="onSubmit">
        <div>
          <div :class="$style.item">
            <p :class="$style.description">{{ $t('examType') }}</p>
            <div :class="$style.answers">
              <BaseRadiobuttonGroup
                v-model="examType"
                :options="examTypeList"
              />
            </div>
          </div>
          <ModalDelimiter />
          <div :class="$style.item">
            <p :class="$style.description">{{ $t('examYear') }}</p>
            <div :class="$style.answers">
              <BaseRadiobuttonGroup
                v-model="examYear"
                :options="examYearList"
              />
            </div>
          </div>
          <ModalDelimiter />
          <div :class="$style.item">
            <p :class="$style.description">{{ $t('friendsStudied') }}</p>
            <div :class="$style.answers">
              <BasePopover ref="popover" placement="right" over-modal>
                <div>
                  <BaseRadiobuttonGroup
                    v-model="hasFriendsInWebium"
                    :options="hasFriendsInWebiumList"
                  />
                </div>
                <template #dropdown>
                  <div :class="$style.popoverMenu">
                    <p :class="$style.popoverTitle">{{ $t('popoverTitle') }}</p>
                    <BaseRadiobuttonGroup
                      v-model="joinReason"
                      :options="joinReasonItems"
                      @change="$refs.popover.hideDropdown()"
                    />
                  </div>
                </template>
              </BasePopover>
            </div>
          </div>
        </div>
      </form>
    </div>
    <template #footerContent>
      <div :class="$style.footer">
        <WButton
          type="button"
          theme="secondary"
          :size="$device.isSmallScreen ? 'l' : 'm'"
          @click="onClose"
        >
          {{ $t('later') }}
        </WButton>
        <WButton
          :size="$device.isSmallScreen ? 'l' : 'm'"
          :disabled="!isFormValid"
          type="submit"
          @click="onSubmit"
        >
          {{ $t('submit') }}
        </WButton>
      </div>
    </template>
  </component>
</template>

<style lang="postcss" module>
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form {
  width: 100%;
}

.item {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  padding: 25px 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;

    &::after {
      display: none;
    }
  }
}

.description {
  font-size: 24px;
  line-height: 28px;
}

.answers {
  --radiobutton-align-items: center;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 2px;
}

.radioItem {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.footer {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
}

.popoverMenu {
  width: 248px;
  padding: 14px;
  padding-bottom: 18px;
}

.popoverTitle {
  margin-bottom: 18px;
  font-size: 18px;
  line-height: 22px;
}

@media (--small-vp) {
  .form {
    width: 100%;
    padding: 0 var(--modal-side-padding);
  }

  .content {
    justify-content: flex-start;
    height: 100%;
    overflow-x: visible;
    overflow-y: clip;
  }

  .item {
    flex-direction: column;
    gap: 16px;
    padding: 20px 0;
  }

  .description {
    font-size: 20px;
    line-height: 24px;
  }

  .answers {
    display: inline-flex;
  }

  .popoverMenu {
    width: 248px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;

    & button {
      width: calc((100% - 16px) / 2);
    }
  }
}
</style>
