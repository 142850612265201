<script>
import { WModal, WModalSheet } from '@WebiumTeam/ui_kit';
import { createNamespacedHelpers } from 'vuex';
import { MODALS } from '~/constants/modals';
import StreaksPairedInvite from '~/components/streaks/streaks-paired/streaks-paired-invite/streaks-paired-invite.vue';

const { mapState, mapActions } = createNamespacedHelpers('modals');
const bgImage = require('../images/bg.svg');
const bgImageMobile = require('../images/bg-mobile.svg');

export const messages = {
  ru: {
    title: 'пригласи бадди в парный стрик',
  },
};

export default {
  components: {
    StreaksPairedInvite,
  },
  i18n: {
    messages,
  },
  computed: {
    ...mapState(['visibleModalName']),
    isVisible() {
      return this.visibleModalName === MODALS.streaksPairedInviteModal;
    },
    modalComponent() {
      return this.$device.isSmallScreen ? WModalSheet : WModal;
    },
    imageUrl() {
      return this.$device.isSmallScreen ? bgImageMobile : bgImage;
    },
  },
  methods: {
    ...mapActions(['closeModal']),
  },
};
</script>

<template>
  <component
    :is="modalComponent"
    :visible="isVisible"
    :class="$style.root"
    data-testid="modal"
    custom-size="600px"
    @close="closeModal"
  >
    <div :class="$style.content">
      <img :class="$style.image" :src="imageUrl" alt="" />
      <strong :class="$style.title">{{ $t('title') }}</strong>
      <StreaksPairedInvite />
    </div>
  </component>
</template>

<style lang="postcss" module>
.root {
  text-align: left;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  color: var(--primary-500);
}

.image {
  margin-bottom: 10px;
  max-width: 100%;
}

.title {
  font-size: 28px;
  line-height: 34px;
}

@media (--small-vp) {
  .image {
    width: 100%;
    margin-bottom: 6px;
  }
}
</style>
