<script>
import { WIconButton } from '@WebiumTeam/ui_kit';
import { createNamespacedHelpers } from 'vuex';
import { EVENT_BUS_KEYS } from '~/constants/event-bus';
import { MODALS } from '~/constants/modals';
import { deletePairedStreakBuddy } from '~/api/streaks';
import { handleError } from '~/services/error-handling/error-handling';
import { eventBus } from '~/services/event-bus';
import BaseConfirmationModal from '~/components/_base/_base-confirmation-modal.vue';
import BasePopover from '~/components/_base/popover/_base-popover.vue';
import BasePopoverMenu from '~/components/_base/popover/_base-popover-menu.vue';

const { mapState, mapActions } = createNamespacedHelpers('modals');
const { mapState: mapStreaksState } = createNamespacedHelpers('streaks');

export const messages = {
  ru: {
    buddyPlaceholder: 'бадди',
    delete: 'удалить {0} из парного стрика',
    confirmationTitle: 'удалить бадди',
    confirmationDescription:
      'ты точно хочешь удалить {0}? ваш парный стрик не сохранится',
    confirmationOkText: 'да, удалить',
  },
};

export default {
  i18n: { messages },
  components: {
    BaseConfirmationModal,
    BasePopover,
    WIconButton,
    BasePopoverMenu,
  },
  computed: {
    ...mapState(['visibleModalName']),
    ...mapStreaksState(['buddy']),
    menuItems() {
      return [
        { value: 'delete', text: this.$t('delete', [this.buddyUsername]) },
      ];
    },
    isConfirmationModalVisible() {
      return this.visibleModalName === MODALS.confirmation;
    },
    confirmationDescription() {
      return this.$t('confirmationDescription', [this.buddyUsername]);
    },
    buddyUsername() {
      let userName = `${this.buddy.firstName} ${this.buddy.lastName}`;
      userName = userName.trim() ? userName : this.$t('buddyPlaceholder');

      return userName;
    },
    classes() {
      return {
        [this.$style.root]: true,
        [this.$style._visible]: this.isConfirmationModalVisible,
      };
    },
  },
  methods: {
    ...mapActions(['closeModal', 'setVisibleModalNameIfPriorityIsHigher']),
    stubClick() {
      return false;
    },
    onMenuItemSelect(key) {
      if (key === 'delete') this.onDelete();
    },
    onDelete() {
      this.setVisibleModalNameIfPriorityIsHigher(MODALS.confirmation);
    },
    async deletePair() {
      try {
        await deletePairedStreakBuddy();
        eventBus.$emit(EVENT_BUS_KEYS.buddyRemoved);
        this.closeModal();
      } catch (error) {
        handleError(error);
      }
    },
  },
};
</script>

<template>
  <div :class="classes">
    <BasePopover
      :class="$style.popover"
      placement="bottom-end"
      :with-offset="$device.isSmallScreen"
      in-modal
    >
      <WIconButton
        theme="emptyLight"
        :size="$device.isSmallScreen ? 's' : 'xs'"
        :icon-name="$device.isSmallScreen ? 'menu-L' : 'menu-S'"
        @click.native.stop.prevent="stubClick"
      />
      <template #dropdown>
        <BasePopoverMenu
          :items="menuItems"
          :class="$style.menu"
          :multi-line="$device.isSmallScreen"
          @select="(key) => onMenuItemSelect(key)"
        />
      </template>
    </BasePopover>
    <BaseConfirmationModal
      :visible="isConfirmationModalVisible"
      :class="$style.confirmationModal"
      :title="$t('confirmationTitle')"
      :description="confirmationDescription"
      :confirmation-text="$t('confirmationOkText')"
      @ok="deletePair"
      @close="closeModal"
    />
  </div>
</template>

<style lang="postcss" module>
.root {
  &._visible {
    visibility: visible;
    opacity: 1;
  }
}

.popover {
  margin-left: 12px;
}

.menu {
  max-width: calc(100vw - 28px);
}

.confirmationModal {
  text-align: left;
}
</style>
