// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._base-confirmation-modal__root-2M\\+\\+Q{color:var(--text-main-color)}._base-confirmation-modal__wrapper-BdrlP{font-size:16px;line-height:20px}._base-confirmation-modal__footer-1G1Xb{display:flex;grid-gap:16px;gap:16px;justify-content:flex-end}@media (max-width:1023px){._base-confirmation-modal__footer-1G1Xb{flex-wrap:wrap-reverse}._base-confirmation-modal__footer-1G1Xb>*{flex-grow:1}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "_base-confirmation-modal__root-2M++Q",
	"wrapper": "_base-confirmation-modal__wrapper-BdrlP",
	"footer": "_base-confirmation-modal__footer-1G1Xb"
};
module.exports = ___CSS_LOADER_EXPORT___;
