// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".free-course-onboarding-modal-escape__wrapper-jt\\+N2{display:flex;flex-direction:column;align-items:center}.free-course-onboarding-modal-escape__image-AlRjq{width:100%;height:auto;margin-bottom:24px;border-radius:12px}.free-course-onboarding-modal-escape__text-Jn-DZ{margin-bottom:8px;text-align:center;font-size:24px;line-height:28px;letter-spacing:.48px}.free-course-onboarding-modal-escape__description-emYJR{text-align:center;font-size:16px;line-height:20px;letter-spacing:.32px}.free-course-onboarding-modal-escape__footer-Bf2pM{display:flex;grid-gap:16px;gap:16px;justify-content:flex-end}@media (max-width:1023px){.free-course-onboarding-modal-escape__image-AlRjq{width:auto;height:238px}.free-course-onboarding-modal-escape__image-AlRjq,.free-course-onboarding-modal-escape__text-Jn-DZ{margin-bottom:16px}.free-course-onboarding-modal-escape__footer-Bf2pM{justify-content:flex-start}.free-course-onboarding-modal-escape__footer-Bf2pM>button{flex:1 1 0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "free-course-onboarding-modal-escape__wrapper-jt+N2",
	"image": "free-course-onboarding-modal-escape__image-AlRjq",
	"text": "free-course-onboarding-modal-escape__text-Jn-DZ",
	"description": "free-course-onboarding-modal-escape__description-emYJR",
	"footer": "free-course-onboarding-modal-escape__footer-Bf2pM"
};
module.exports = ___CSS_LOADER_EXPORT___;
