// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".free-course-onboarding-modal-intro__wrapper-5NwNt{display:flex;flex-direction:column}.free-course-onboarding-modal-intro__image-\\+aQJu{position:relative;display:flex;align-items:center;justify-content:center;width:100%;margin-bottom:16px}.free-course-onboarding-modal-intro__background-AR8yA{width:100%;height:auto;border-radius:12px}.free-course-onboarding-modal-intro__avatar-FhlOs{position:absolute;top:14px;left:50%;transform:translateX(-50%)}.free-course-onboarding-modal-intro__text-Ffa2s{text-align:center;font-size:16px;line-height:20px;letter-spacing:.32px}.free-course-onboarding-modal-intro__text-Ffa2s:not(:last-child){margin-bottom:8px}.free-course-onboarding-modal-intro__text-Ffa2s:nth-of-type(2){margin-bottom:16px}.free-course-onboarding-modal-intro__footer-Qeiao{display:flex;grid-gap:16px;gap:16px;width:100%}.free-course-onboarding-modal-intro__footer-Qeiao>button{flex:1 1 0}@media (max-width:1023px){.free-course-onboarding-modal-intro__text-Ffa2s:nth-of-type(2){margin-bottom:8px}.free-course-onboarding-modal-intro__footer-Qeiao{flex-direction:column-reverse;grid-gap:12px;gap:12px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "free-course-onboarding-modal-intro__wrapper-5NwNt",
	"image": "free-course-onboarding-modal-intro__image-+aQJu",
	"background": "free-course-onboarding-modal-intro__background-AR8yA",
	"avatar": "free-course-onboarding-modal-intro__avatar-FhlOs",
	"text": "free-course-onboarding-modal-intro__text-Ffa2s",
	"footer": "free-course-onboarding-modal-intro__footer-Qeiao"
};
module.exports = ___CSS_LOADER_EXPORT___;
