<script>
import { WButton, WIconButton } from '@WebiumTeam/ui_kit';
import { createNamespacedHelpers } from 'vuex';
import { FREE_COURSE_ONBOARDING_STEPS } from '~/constants/free-course-onboarding';
import BaseSegmentedProgress from '~/components/_base/_base-segmented-progress.vue';
import StepCourseContacts from './tooltip-steps/step-course-contacts.vue';
import StepCourseHomeworks from './tooltip-steps/step-course-homeworks.vue';
import StepCourseHomeworksTimelineMobile from './tooltip-steps/step-course-homeworks-timeline-mobile.vue';
import StepCourseSchedule from './tooltip-steps/step-course-schedule.vue';
import StepCourseStreaks from './tooltip-steps/step-course-streaks.vue';
import StepCourseTask from './tooltip-steps/step-course-task.vue';
import StepCourseWebinars from './tooltip-steps/step-course-webinars.vue';

const { mapState, mapGetters } = createNamespacedHelpers(
  'free-course-onboarding'
);

export const messages = {
  ru: {
    schedule: 'расписание',
    webinars: 'видеоуроки и вебинары',
    streaks: 'стрики',
    homeworks: 'домашки и пробники',
    contacts: 'контакты и полезности',
    buttonText: 'вперёд!',
  },
};

export default {
  i18n: { messages },
  components: {
    WIconButton,
    WButton,
    BaseSegmentedProgress,
    StepCourseSchedule,
    StepCourseWebinars,
    StepCourseStreaks,
    StepCourseHomeworks,
    StepCourseHomeworksTimelineMobile,
    StepCourseTask,
    StepCourseContacts,
  },
  props: {
    teacherAvatarSrc: {
      type: String,
      required: true,
    },
    activeStepKey: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState(['steps', 'tooltipPosition']),
    ...mapGetters(['isTooltipVisible', 'activeStepProgressIndex']),
    tooltipStyles() {
      return {
        left: this.tooltipPosition ? `${this.tooltipPosition.left}px` : 0,
        top: this.tooltipPosition ? `${this.tooltipPosition.top}px` : 0,
        opacity: this.tooltipPosition ? 1 : 0,
        visibility: this.tooltipPosition ? 'visible' : 'hidden',
      };
    },
    onboardingMaxSteps() {
      return this.steps.length - 2;
    },
    teacherAvatar() {
      return this.course.teachers[0].avatar;
    },
    currentStepTitle() {
      const title = {
        [FREE_COURSE_ONBOARDING_STEPS.courseSchedule]: this.$t('schedule'),
        [FREE_COURSE_ONBOARDING_STEPS.courseWebinars]: this.$t('webinars'),
        [FREE_COURSE_ONBOARDING_STEPS.courseStreaks]: this.$t('streaks'),
        [FREE_COURSE_ONBOARDING_STEPS.courseHomeworks]: this.$t('homeworks'),
        [FREE_COURSE_ONBOARDING_STEPS.courseTask]: this.$t('homeworks'),
        [FREE_COURSE_ONBOARDING_STEPS.courseHomeworksTimelineMobile]:
          this.$t('homeworks'),
        [FREE_COURSE_ONBOARDING_STEPS.courseContacts]: this.$t('contacts'),
      };
      return title[this.activeStepKey];
    },
    contentComponent() {
      return this.convertStepKeyToComponentName(this.activeStepKey);
    },
    isContactsStep() {
      return FREE_COURSE_ONBOARDING_STEPS.courseContacts === this.activeStepKey;
    },
  },
  methods: {
    convertStepKeyToComponentName(stepKey) {
      if (!stepKey) return '';
      const stepName = stepKey.replace(/^./, stepKey[0]?.toUpperCase());
      return `Step${stepName}`;
    },
  },
};
</script>

<template>
  <div
    id="free-course-onboarding-tooltip"
    :class="$style.root"
    :style="tooltipStyles"
  >
    <div :class="$style.avatar">
      <img
        :src="teacherAvatarSrc"
        width="72"
        height="72"
        alt=""
        data-testid="teacher-avatar"
      />
    </div>
    <div :class="$style.wrapper">
      <img
        :class="$style.arrow"
        :src="require('./images/tooltip-arrow.svg')"
        width="24"
        height="36"
        alt=""
      />
      <div :class="$style.header" data-testid="step-title">
        {{ currentStepTitle }}
        <WIconButton
          icon-name="close-S"
          size="m"
          theme="emptyLight"
          @click="$emit('close')"
        />
      </div>
      <BaseSegmentedProgress
        :class="$style.segmentedProgress"
        :segments="[onboardingMaxSteps]"
        :value="activeStepProgressIndex"
        size="xs"
      />
      <div :class="$style.content">
        <component :is="contentComponent"></component>
        <WButton
          v-if="isContactsStep"
          size="l"
          theme="secondary"
          data-testid="step-contacts-button"
          @click="$emit('step-button-click')"
          >{{ $t('buttonText') }}</WButton
        >
      </div>
    </div>
  </div>
</template>

<style lang="postcss" module>
.root {
  position: absolute;
  z-index: 3000000000;
  display: flex;
  gap: 26px;
  align-items: flex-end;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 254px;
  border-radius: 8px;
  background-color: var(--primary-100);
}

.arrow {
  position: absolute;
  bottom: 0;
  left: -22px;
  width: 24px;
  height: auto;
}

.avatar {
  flex-shrink: 0;
  width: 72px;
  height: 72px;
  border-radius: 50%;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  padding-left: 20px;
  color: var(--grey-600);
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.32px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 20px 18px;
}

@media (--xx-small-vp) {
  .wrapper {
    width: 238px;
  }
}
</style>
