<script>
import { WIconButton, WTooltip } from '@WebiumTeam/ui_kit';
import { sizeValidator } from '~/constants/ui';

export default {
  components: {
    WIconButton,
    WTooltip,
  },
  props: {
    leftDisabled: {
      type: Boolean,
      default: false,
    },
    rightDisabled: {
      type: Boolean,
      default: false,
    },
    leftDisabledText: {
      type: String,
      default: '',
    },
    rightDisabledText: {
      type: String,
      default: '',
    },
    leftClass: {
      type: String,
      default: '',
    },
    rightClass: {
      type: String,
      default: '',
    },
    split: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'm',
      validate: sizeValidator,
    },
  },
  computed: {
    classes() {
      return {
        [this.$style.root]: true,
        [this.$style.root_hasContent]: !!this.$slots.default,
        [this.$style.root_joined]: !!this.split === false,
      };
    },
  },
};
</script>

<template>
  <div :class="classes">
    <WTooltip
      data-testid="left-tooltip"
      :class="$style.tooltip"
      :disabled="!leftDisabled"
      :text="leftDisabledText"
    >
      <div>
        <WIconButton
          icon-name="arrow-left-S"
          theme="tetriary"
          data-testid="left-button"
          :size="size"
          :disabled="leftDisabled"
          :class="[$style.button, $style.button_left, leftClass]"
          @click="$emit('click-left')"
        />
      </div>
    </WTooltip>
    <slot></slot>
    <WTooltip
      data-testid="right-tooltip"
      :class="$style.tooltip"
      :disabled="!rightDisabled"
      :text="rightDisabledText"
    >
      <div>
        <WIconButton
          icon-name="arrow-right-S"
          theme="tetriary"
          data-testid="right-button"
          :size="size"
          :disabled="rightDisabled"
          :class="[$style.button, $style.button_right, rightClass]"
          @click="$emit('click-right')"
        />
      </div>
    </WTooltip>
  </div>
</template>

<style lang="postcss" module>
.tooltip {
  &,
  & > * {
    height: 100%;
  }
}

.root {
  display: flex;
  gap: 0;
  height: inherit;
}

.root_joined {
  & .button_left {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & .button_right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.root_hasContent {
    & .button_right {
      border-left: none;
    }
  }
}

@media (--small-vp) {
  .root {
    gap: 0;
  }
}
</style>
