function lazyLoad(importedModule) {
  return importedModule.then((module) => module.default || module);
}

export const ROUTE_META_KEYS = {
  useCommonOnboarding: 'useCommonOnboarding',
};

const routes = Object.freeze([
  {
    path: '',
    name: 'index',
    redirect: { name: 'dashboard' },
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => lazyLoad(import('~/pages/auth.vue')),
  },
  {
    path: '/courses',
    name: 'courses',
    component: () => lazyLoad(import('~/pages/courses.vue')),
  },
  {
    path: '/courses/archive',
    name: 'courses-archive',
    component: () => lazyLoad(import('~/pages/courses-archive.vue')),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      [ROUTE_META_KEYS.useCommonOnboarding]: true,
    },
    component: () => lazyLoad(import('~/pages/dashboard.vue')),
  },
  {
    path: '/achievements',
    name: 'achievements',
    component: () => lazyLoad(import('~/pages/achievements.vue')),
  },
  {
    path: '/results2024',
    name: 'results',
    meta: { sendOpenerToLogin: true },
    component: () => lazyLoad(import('~/pages/results.vue')),
  },
  {
    path: '/timetable/:courseId?',
    name: 'timetable',
    component: () => lazyLoad(import('~/pages/timetable.vue')),
  },
  {
    path: '/notifications/:notificationId',
    name: 'notification',
    component: () => lazyLoad(import('~/pages/notification.vue')),
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => lazyLoad(import('~/pages/notifications.vue')),
  },
  {
    path: '/course/:id',
    name: 'course-dashboard',
    meta: { initFreeCourseOnboarding: true },
    component: () => lazyLoad(import('~/pages/course-dashboard.vue')),
  },
  {
    path: '/course-by-public-id/:coursePublicId/',
    name: 'course-by-public-id',
    component: () => lazyLoad(import('~/pages/course-by-public-id.vue')),
  },
  {
    path: '/course/:id/incompleted-webinars',
    name: 'course-incompleted-webinars',
    component: () => lazyLoad(import('~/pages/incompleted-webinars.vue')),
  },
  {
    path: '/course/:id/incompleted-homework',
    name: 'course-incompleted-homework',
    component: () => lazyLoad(import('~/pages/incompleted-homework.vue')),
  },
  {
    path: '/course/:id?/content',
    name: 'course-content',
    component: () => lazyLoad(import('~/pages/course-content.vue')),
    children: [
      {
        path: 'homework/:homeworkId?',
        name: 'course-content-homework',
        meta: { initFreeCourseOnboarding: true },
        component: () =>
          lazyLoad(import('~/pages/course-content-homework.vue')),
      },
      {
        path: 'info/:infoPageId?',
        name: 'course-content-info',
        component: () => lazyLoad(import('~/pages/course-content-info.vue')),
      },
      {
        path: 'webinar/:webinarId?',
        name: 'course-content-webinar',
        meta: { initFreeCourseOnboarding: true },
        component: () => lazyLoad(import('~/pages/course-content-webinar.vue')),
      },
      {
        path: 'workout/:themeId/:subThemeId?',
        name: 'course-content-workout',
        component: () => lazyLoad(import('~/pages/course-content-workout.vue')),
      },
      {
        path: 'workout-kim/:topicKimNumberId',
        name: 'course-content-workout-kim',
        component: () => lazyLoad(import('~/pages/course-content-workout.vue')),
      },
    ],
  },
  {
    path: '/paired-streak-invitation/:id',
    name: 'paired-streak-invitation',
    component: () => lazyLoad(import('~/pages/paired-streak-invitation.vue')),
  },
  {
    path: '/ui-kit',
    name: 'ui-kit',
    component: () => lazyLoad(import('~/pages/ui-kit.vue')),
  },
  {
    path: '/schedule',
    name: 'legacy-schedule',
    redirect: { name: 'timetable' },
  },
  {
    path: '/purchases',
    name: 'legacy-purchases',
    redirect: { name: 'courses' },
  },
  {
    path: '/courses/:slug',
    name: 'legacy-course',
    redirect: { name: 'courses' },
  },
  {
    path: '/lessons/:id',
    name: 'legacy-lesson',
    redirect: { name: 'courses' },
  },
]);

/* eslint-disable import/no-default-export */
export default routes;
