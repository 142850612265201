import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import snakeCase from 'lodash/snakeCase';
import transform from 'lodash/transform';
import { http } from '~/services/http';

const baseUrl = 'https://analytics.webium.ru';

const snakeCaseDeep = (object) =>
  transform(object, (accumulator, value, key, target) => {
    const camelKey = isArray(target) ? key : snakeCase(key);
    accumulator[camelKey] = isObject(value) ? snakeCaseDeep(value) : value;
  });

export function trackAnalyticsEvents({ id, events = [], isSingleEvent }) {
  const mappedEvents = events.map((eventItem) =>
    snakeCaseDeep({
      ...eventItem,
      eventName: eventItem.eventName
        ? `front.${eventItem.eventName}`
        : undefined,
    })
  );
  const payload =
    mappedEvents.length === 1 && isSingleEvent ? mappedEvents[0] : mappedEvents;
  return http.post(`${baseUrl}/api/v2/webhooks/${id}/`, payload);
}
