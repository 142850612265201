<script>
import { createNamespacedHelpers } from 'vuex';
import { convertToRoman } from '~/test/helpers/numbers';
import { EVENT_BUS_KEYS } from '~/constants/event-bus';
import { isFreeCourseOnboardingActive } from '~/utils/free-course-onboarding';
import { replacePlaceholder } from '~/utils/strings';
import { eventBus } from '~/services/event-bus';
import BaseToastMessage from '~/components/_base/_base-toast-message.vue';
import MotivationInfo from '~/components/motivation/motivation-info.vue';

const { mapState, mapActions } = createNamespacedHelpers('motivation');
const { mapState: mapStreaksState } = createNamespacedHelpers('streaks');

const defaultImage = require('~/components/motivation/achievement-default.svg');

const xpAmountPlaceholder = 'xp_amount';

const experienceIcon = `<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.25029 0.584042C5.25893 0.259002 5.52492 0 5.85007 0H6.14993C6.47487 0 6.74076 0.258674 6.7497 0.583493C6.81985 3.13237 8.87071 5.18251 11.416 5.25029C11.741 5.25894 12 5.52492 12 5.85007V6.14993C12 6.47508 11.741 6.74106 11.416 6.74972C8.87023 6.81751 6.8175 8.86799 6.74972 11.416C6.74107 11.741 6.47508 12 6.14993 12H5.85007C5.52492 12 5.25894 11.741 5.25029 11.416C5.18249 8.87023 3.13201 6.81751 0.584042 6.74972C0.259002 6.74107 0 6.47508 0 6.14993V5.85007C0 5.52492 0.258993 5.25894 0.584028 5.25029C3.12977 5.18249 5.1825 3.13201 5.25029 0.584042Z" fill="#42BD86"/>
</svg>`;

export const messages = {
  ru: {
    title: 'ура, новая ачивка!',
    description:
      'лови ачивку и {{xp_amount}}, а теперь вперёд за следующей наградой!',
    level: '{level} уровень',
  },
};

export default {
  i18n: {
    messages,
  },
  components: {
    MotivationInfo,
    BaseToastMessage,
  },
  data() {
    return {
      visibleAchievements: [],
      isAchievementsMessagesEnableToShow: false,
    };
  },
  computed: {
    ...mapState(['achievementsReceivedNow']),
    ...mapStreaksState(['streakReceivedNow']),
  },
  watch: {
    achievementsReceivedNow: {
      immediate: true,
      handler(achievementsReceivedNow) {
        const newAchievements = (achievementsReceivedNow || []).filter(
          (newAchievement) =>
            !this.visibleAchievements.some(
              (visibleAchievement) =>
                visibleAchievement.id === newAchievement.id
            )
        );
        if (!newAchievements || newAchievements.length === 0) return;
        this.visibleAchievements = [
          ...newAchievements,
          ...this.visibleAchievements,
        ];
        if (!this.isAchievementsMessagesEnableToShow) return;
        this.handleDisplayNewAchievementsMessages();
      },
    },
    isAchievementsMessagesEnableToShow(value) {
      if (value && this.visibleAchievements.length > 0) {
        this.handleDisplayNewAchievementsMessages();
      }
    },
  },
  mounted() {
    eventBus.$on(EVENT_BUS_KEYS.freeCourseOnboardingABTestHappend, () => {
      const isHideAllPopups = isFreeCourseOnboardingActive();
      if (isHideAllPopups) {
        this.visibleAchievements = [];
        return;
      }
      this.isAchievementsMessagesEnableToShow = true;
    });
  },
  methods: {
    ...mapActions(['markAchievementReceivedNowAsViewed']),
    achievementImage(achievement) {
      return achievement?.image || defaultImage;
    },
    achievementDescription(achievementText, xp) {
      return replacePlaceholder({
        text: achievementText,
        placeholder: xpAmountPlaceholder,
        newValue: `<span>+${xp}${experienceIcon}</span>`,
      });
    },
    achievementLevel(achievementStage) {
      return achievementStage
        ? this.$t('level', { level: convertToRoman(achievementStage) })
        : '';
    },
    onClose(achievementId) {
      this.visibleAchievements = this.visibleAchievements.filter(
        (achievement) => achievement.id !== achievementId
      );
      this.markAchievementReceivedNowAsViewed(achievementId);
    },
    showMessage(achievementId) {
      this.$nextTick().then(
        this.$nextTick(() => {
          this.$refs[`message-${achievementId}`][0].show();
        })
      );
    },
    showAchievementsMessages() {
      this.visibleAchievements.forEach((item, index) => {
        setTimeout(() => {
          this.showMessage(item.id);
        }, index * 400);
      });
    },
    handleDisplayNewAchievementsMessages() {
      setTimeout(() => {
        if (this.streakReceivedNow) {
          const unwatch = this.$watch('streakReceivedNow', (newValue) => {
            if (!newValue) {
              this.showAchievementsMessages();
            }
            unwatch();
          });
        } else {
          this.showAchievementsMessages();
        }
      }, 500);
    },
  },
};
</script>

<template>
  <div>
    <Portal to="toast-messages">
      <BaseToastMessage
        v-for="achievement in visibleAchievements"
        :key="achievement.id"
        :ref="`message-${achievement.id}`"
        auto-hide
        @close="onClose(achievement.id)"
      >
        <MotivationInfo
          :class="$style.info"
          :description="
            achievementDescription(
              $t('description'),
              achievement.displayXpAmount
            )
          "
          :title="$t('title')"
          :image="achievementImage(achievement)"
          :level="achievementLevel(achievement.stage)"
        />
      </BaseToastMessage>
    </Portal>
  </div>
</template>

<style lang="postcss" module>
.info {
  --motivation-image-width: 82px;
  --motivation-image-height: 82px;
  --motivation-info-padding: 6px 40px 6px 10px;
  --motivation-title-font-size: 18px;
  --motivation-title-line-height: 22px;
}

@media (--small-vp) {
  .info {
    --motivation-image-width: 72px;
    --motivation-image-height: 72px;
    --motivation-info-padding: 6px 12px;
    --motivation-title-font-size: 16px;
    --motivation-title-line-height: 20px;
  }
}
</style>
