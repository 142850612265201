<script>
import { WLoading, WTooltip } from '@WebiumTeam/ui_kit';
import cloneDeep from 'lodash/cloneDeep';
import { createNamespacedHelpers } from 'vuex';
import { AVAILABLE_NOTIFICATION_TYPES } from '~/constants/notifications';
import BaseCheckbox from '~/components/_base/_base-checkbox.vue';
import InfoTooltip from '~/components/info-tooltip.vue';
import ModalDelimiter from '~/components/modal-delimiter.vue';
import NotificationSettingsRow from '~/components/notifications/notification-settings-row.vue';
import TgNotificationsWidget from '~/components/tg-notifications-widget.vue';
import VkCommunityNotificationsWidget from '~/components/vk-community-notifications-widget.vue';

export const messages = {
  ru: {
    vkNotificationsName: 'получать уведомления Вконтакте',
    tgNotificationsName: 'получать уведомления в Telegram',
    tgNotificationsHint:
      'если заБОТа заблокирована в Telegram,\n уведомления приходить не будут',
    vkNeedToBeEnabledMessage:
      'подключи уведомления Вконтакте,<br/>чтобы настроить эти параметры',
    tgNeedToBeEnabledMessage:
      'подключи уведомления в Telegram,<br/>чтобы настроить эти параметры',
    types: {
      isVkAllowed: 'Вконтакте',
      isTextAllowed: 'на сайте',
      isTgAllowed: 'в Telegram',
    },
    successSaveMessage: 'Настройки уведомлений сохранены',
  },
};

const { mapState, mapActions } = createNamespacedHelpers(
  'notification-settings'
);

export default {
  AVAILABLE_NOTIFICATION_TYPES,
  i18n: { messages },
  components: {
    ModalDelimiter,
    BaseCheckbox,
    WTooltip,
    WLoading,
    VkCommunityNotificationsWidget,
    TgNotificationsWidget,
    NotificationSettingsRow,
    InfoTooltip,
  },
  data() {
    return {
      form: {},
    };
  },
  async fetch() {
    await this.fetchNotificationsSettings();
  },
  computed: {
    ...mapState(['settings', 'loading']),
    isTgAllowButtonForced() {
      return (
        this.$auth.user.isTgNotificationsAllowed &&
        this.isAllCheckboxesEmptyByType('isTgAllowed')
      );
    },
  },
  watch: {
    settings: {
      immediate: true,
      handler(settings) {
        const form = cloneDeep(settings);
        form.forEach((item) => {
          /* eslint-disable no-param-reassign */
          if (!this.$auth.user.isTgNotificationsAllowed) {
            item.config.isTgAllowed = false;
          }
          if (!this.$auth.user.isVkNotificationsAllowed) {
            item.config.isVkAllowed = false;
          }
          /* eslint-enable */
        });
        this.form = form;
      },
    },
  },
  methods: {
    ...mapActions([
      'saveSettings',
      'fetchNotificationsSettings',
      'closeSettingsModal',
    ]),
    async save() {
      await this.saveSettings(this.form);
      this.$toast.success(this.$t('successSaveMessage'));
      this.closeSettingsModal();
    },
    isOptionDisabled(config, type) {
      const value = config[type];
      if (value === true) return false;
      if (type === 'isVkAllowed')
        return this.$auth.user.isVkNotificationsAllowed === false;
      if (type === 'isTgAllowed') {
        return (
          !this.$auth.user.tgId ||
          this.$auth.user.isTgNotificationsAllowed === false
        );
      }
      return false;
    },
    isAllCheckboxesEmptyByType(type) {
      return this.form.every((item) => !item.config[type]);
    },
    getOptionTooltip(config, type) {
      if (type === 'isVkAllowed' && this.isOptionDisabled(config, type)) {
        return this.$t('vkNeedToBeEnabledMessage');
      }
      if (type === 'isTgAllowed' && this.isOptionDisabled(config, type)) {
        return this.$t('tgNeedToBeEnabledMessage');
      }
      return '';
    },
    setAllCheckboxesByType(type, value) {
      this.form = this.form.map((item) => ({
        ...item,
        config: {
          ...item.config,
          [type]: !!value,
        },
      }));
    },
    onVkAllow() {
      this.setAllCheckboxesByType('isVkAllowed', true);
    },
    onVkDeny() {
      this.setAllCheckboxesByType('isVkAllowed', false);
    },
    onTgAllow() {
      this.setAllCheckboxesByType('isTgAllowed', true);
    },
    onTgDeny() {
      this.setAllCheckboxesByType('isTgAllowed', false);
    },
  },
};
</script>

<template>
  <div :class="$style.root">
    <WLoading :class="$style.loading" :show="loading" overlay />
    <div :class="$style.content">
      <div :class="$style.connectButtons">
        <div :class="$style.connectButtonWrapper">
          <span>{{ $t('vkNotificationsName') }}</span>
          <VkCommunityNotificationsWidget
            element-id="vk-notifications-settings"
            @allow="onVkAllow"
            @deny="onVkDeny"
          />
        </div>
        <div :class="$style.connectButtonWrapper">
          <div :class="$style.connectButtonTitleWrapper">
            <span>{{ $t('tgNotificationsName') }}</span>
            <Transition>
              <InfoTooltip
                v-if="$auth.user.isTgNotificationsAllowed"
                placement="top"
                :class="$style.connectButtonTitleHint"
                :popper-class="$style.connectButtonPopper"
                :text="$t('tgNotificationsHint')"
              />
            </Transition>
          </div>
          <TgNotificationsWidget
            :force-allow-button="isTgAllowButtonForced"
            @allow="onTgAllow"
            @deny="onTgDeny"
          />
        </div>
      </div>
      <ModalDelimiter />
      <div :class="$style.group">
        <NotificationSettingsRow
          v-for="settingsItem in form"
          :key="settingsItem.id"
          :name="settingsItem.name"
          :description="settingsItem.description"
        >
          <WTooltip
            v-for="type in $options.AVAILABLE_NOTIFICATION_TYPES"
            :key="type"
            :text="getOptionTooltip(settingsItem.config, type)"
          >
            <BaseCheckbox
              v-model="settingsItem.config[type]"
              :disabled="isOptionDisabled(settingsItem.config, type)"
              :label="$t(`types.${type}`)"
              small
            />
          </WTooltip>
        </NotificationSettingsRow>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" module>
.root {
  --group-gap: 24px;

  display: flex;
  flex-direction: column;
  height: inherit;

  & .loading {
    border-radius: 8px;
  }
}

.group {
  display: flex;
  flex-direction: column;
  gap: var(--group-gap);
  align-content: flex-start;
  padding: 24px 0;
}

.content {
  max-height: 62vh;
}

.connectButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  padding: 8px 0 28px;
}

.connectButtonWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.connectButtonTitleWrapper {
  position: relative;
  display: flex;
  gap: 8px;
}

.connectButtonTitleHint {
  position: absolute;
  right: -30px;
  top: -2px;
}

.connectButtonPopper {
  --max-width: 248px;
}

@media (--small-vp) {
  .group {
    --group-gap: 32px;
  }

  .content {
    max-height: 100%;
  }

  .connectButtons {
    gap: 20px;
    padding: 24px 0;
  }
}
</style>
