<script>
import { WButton, WIconButton } from '@WebiumTeam/ui_kit';
import { createNamespacedHelpers } from 'vuex';
import { TOOLTIPS } from '~/constants/tooltips';
import { isFreeCourseOnboardingActive } from '~/utils/free-course-onboarding';
import { getLSItem, setLSItem } from '~/services/local-storage';
import { openTgConnectModal } from '~/services/tg-connect';

const { mapState: mapCommonOnboardingState } =
  createNamespacedHelpers('common-onboarding');

export const messages = {
  ru: {
    title: 'получай важные уведомления в Telegram',
    content:
      '<ul><li><span>напомним тебе о предстоящих вебинарах и домашках</span></li><li><span>сообщим о проверенных ДЗ и ответах наставника</span></li></ul>',
    button: 'подключить уведомления',
  },
};

const closedAtLsKey = 'telegram_link_banner_closed_at';
const firstLoadedAtLsKey = 'first_telegram_link_banner_loaded_at';
const firstShowDelay = 24 * 60 * 60 * 1000;
const showDelay = 2000;

export default {
  i18n: {
    messages,
  },
  components: {
    WButton,
    WIconButton,
  },
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    ...mapCommonOnboardingState({
      commonOnboardingCouldBeShown: 'couldBeShown',
    }),
    notEnoughTimePassedSinceFirstLoad() {
      return (
        getLSItem(firstLoadedAtLsKey) &&
        Date.now() - +getLSItem(firstLoadedAtLsKey) < firstShowDelay
      );
    },
    couldBeShown() {
      /* eslint-disable sonarjs/prefer-single-boolean-return */
      if (this.$auth.user.tgId) return false;
      if (this.$auth.user.isTgNotificationsAllowed) return false;
      if (getLSItem(closedAtLsKey)) return false;
      if (this.commonOnboardingCouldBeShown) return false;
      if (this.notEnoughTimePassedSinceFirstLoad) return false;
      return true;
      /* eslint-enable sonarjs/prefer-single-boolean-return */
    },
  },
  created() {
    const isHideAllPopups = isFreeCourseOnboardingActive();
    if (isHideAllPopups) return;
    this.saveFirstLoadedDate();
  },
  mounted() {
    const isHideAllPopups = isFreeCourseOnboardingActive();
    if (isHideAllPopups) return;
    this.handleShow();
  },
  methods: {
    handleShow() {
      setTimeout(() => {
        this.isVisible = !!this.couldBeShown;
      }, showDelay);
    },
    close() {
      this.isVisible = false;
      setLSItem(closedAtLsKey, Date.now());
      this.$tooltips.show(TOOLTIPS.notificationsSettings);
    },
    onButtonClick() {
      openTgConnectModal(this.$auth);
      this.close();
    },
    saveFirstLoadedDate() {
      if (process.client && !getLSItem(firstLoadedAtLsKey)) {
        setLSItem(firstLoadedAtLsKey, Date.now());
      }
    },
  },
};
</script>

<template>
  <div>
    <Transition>
      <div v-if="isVisible" data-testid="root" :class="$style.root">
        <WIconButton
          :class="$style.closeButton"
          theme="emptyLight"
          icon-name="close-S"
          data-testid="button-close"
          size="m"
          @click="close"
        />
        <div :class="$style.header">
          <img
            alt=""
            :class="$style.sticker"
            :src="require('./images/sticker.svg')"
          />
          <strong :class="$style.title">{{ $t('title') }}</strong>
        </div>
        <div :class="$style.content" v-html="$t('content')"></div>
        <WButton
          :class="$style.button"
          data-testid="button-link"
          size="m"
          @click="onButtonClick"
        >
          {{ $t('button') }}
        </WButton>
      </div>
    </Transition>
  </div>
</template>

<style lang="postcss" module>
.root {
  z-index: 2;
  position: fixed;
  left: 24px;
  bottom: 24px;
  display: flex;
  flex-direction: column;
  width: 350px;
  padding: 20px;
  background-color: var(--primary-100);
  border-radius: 8px;
  border: solid 2px var(--primary-500);
  box-shadow: var(--dropdown-shadow);
}

.closeButton {
  position: absolute;
  top: 2px;
  right: 2px;
}

.header {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
}

.sticker {
  width: 72px;
  height: 72px;
}

.title {
  margin-top: 8px;
  font-size: 20px;
  line-height: 24px;
}

.content {
  & ul {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 0 0 0 20px;
    padding: 0;

    & li {
      font-size: 16px;
      line-height: 20px;
      list-style-image: url('./images/bullet.svg');

      & span {
        position: relative;
        top: -4px;
      }

      &::marker {
        position: relative;
        top: 4px;
      }
    }
  }
}

.button {
  width: 100%;
  margin-top: 20px;
}

@media (--small-vp) {
  .root {
    z-index: var(--base-toast-message-z-index);
    right: 12px;
    bottom: 12px;
    left: 12px;
    width: auto;
  }
}
</style>
