<script>
/* eslint-disable sonarjs/no-duplicate-string */
import { createNamespacedHelpers } from 'vuex';
import { EVENT_BUS_KEYS } from '~/constants/event-bus';
import { FREE_COURSE_ONBOARDING_STEPS } from '~/constants/free-course-onboarding';
import { COURSE_ONBOARDING_TYPES } from '~/constants/onboarding';
import { closeOnboarding, finishOnboarding } from '~/api/student-courses';
import { handleError } from '~/services/error-handling/error-handling';
import { eventBus } from '~/services/event-bus';
import { gtmEvents, gtmPushEvent } from '~/services/gtm/gtm';
import FreeCourseOnboardingModalEscape from './free-course-onboarding-modal-escape.vue';
import FreeCourseOnboardingModalFinish from './free-course-onboarding-modal-finish.vue';
import FreeCourseOnboardingModalIntro from './free-course-onboarding-modal-intro.vue';
import FreeCourseOnboardingTooltip from './free-course-onboarding-tooltip.vue';

const { mapState, mapGetters, mapActions } = createNamespacedHelpers(
  'free-course-onboarding'
);
const { mapState: currentCourseState } =
  createNamespacedHelpers('current-course');
const { mapState: streaksState, mapActions: streaksActions } =
  createNamespacedHelpers('streaks');
const { mapState: baseState } = createNamespacedHelpers('base');

const FREE_COURSE_ONBOARDING_STEP_HOMEWORK_DELAY = 3000;

export const messages = {
  ru: {},
};

export default {
  i18n: { messages },
  components: {
    FreeCourseOnboardingModalIntro,
    FreeCourseOnboardingModalEscape,
    FreeCourseOnboardingTooltip,
    FreeCourseOnboardingModalFinish,
  },
  data() {
    return {
      swithcOnboardingToStreakReceivedStepOnReady: false,
    };
  },
  computed: {
    ...mapState([
      'activeStepId',
      'steps',
      'tooltipPosition',
      'isEscapeOnboardingModalVisible',
    ]),
    ...streaksState({
      streakReceivedNow: 'streakReceivedNow',
      isStreaksLoading: 'isLoading',
    }),
    ...baseState(['serverDate']),
    ...mapGetters(['activeStepWithTooltip', 'isTooltipVisible']),
    ...currentCourseState(['currentCourse']),
    isOnboardingActive() {
      return !!this.activeStepId;
    },
    isIntroModalVisible() {
      return this.activeStepId === FREE_COURSE_ONBOARDING_STEPS.startOnboarding;
    },
    isFinishModalVisible() {
      return (
        this.activeStepId === FREE_COURSE_ONBOARDING_STEPS.finishOnboarding
      );
    },
    isOnboardingWebinarStepActive() {
      return this.activeStepId === FREE_COURSE_ONBOARDING_STEPS.courseWebinars;
    },
    isOnboardingStreakStepActive() {
      return this.activeStepId === FREE_COURSE_ONBOARDING_STEPS.courseStreaks;
    },
    isOnboardingCourseTaskStepActive() {
      return this.activeStepId === FREE_COURSE_ONBOARDING_STEPS.courseTask;
    },
    isOnboardingContactsStepActive() {
      return this.activeStepId === FREE_COURSE_ONBOARDING_STEPS.courseContacts;
    },
    teacherAvatar() {
      return this.currentCourse.teachers[0].avatar;
    },
  },
  watch: {
    isStreaksLoading(value) {
      if (!this.swithcOnboardingToStreakReceivedStepOnReady) return;
      if (value) return;
      if (!this.streakReceivedNow) this.markStreakAsNotViewed();
    },
    serverDate() {
      if (!this.isOnboardingActive) return;
      if (this.isEscapeOnboardingModalVisible) return;
      if (this.isIntroModalVisible) return;
      if (this.isFinishModalVisible) return;
      if (this.isOnboardingWebinarStepActive) return;
      this.$nextTick(() => {
        this.applyActiveStep();
      });
    },
  },
  mounted() {
    this.init();
    if (this.isOnboardingStreakStepActive) {
      this.swithcOnboardingToStreakReceivedStepOnReady = true;
    }
    this.applyListeners();
    window.addEventListener('popstate', () => {
      if (this.isOnboardingActive) this.closeFromNavigation();
    });
    this.markOnboardingAsClosed();
  },
  beforeDestroy() {
    this.closeOnboarding();
  },
  methods: {
    ...mapActions([
      'init',
      'switchEscapeOnboardingModalVisibility',
      'closeOnboarding',
      'switchStep',
      'removeWaveClasses',
      'applyActiveStep',
    ]),
    async markOnboardingAsClosed() {
      try {
        await closeOnboarding({
          courseId: this.currentCourse.id,
          onboardingType: COURSE_ONBOARDING_TYPES.excursion,
        });
      } catch (error) {
        handleError(error);
      }
    },
    async markOnboardingAsCompleted() {
      try {
        await finishOnboarding({
          courseId: this.currentCourse.id,
          onboardingType: COURSE_ONBOARDING_TYPES.excursion,
        });
      } catch (error) {
        handleError(error);
      }
    },
    ...streaksActions(['markStreakAsNotViewed']),
    showEscapeOnboardingModal() {
      this.switchEscapeOnboardingModalVisibility(true);
    },
    hideEscapeOnboardingModal() {
      this.switchEscapeOnboardingModalVisibility(false);
    },
    closeFromNavigation() {
      gtmPushEvent(gtmEvents.onboardingEscapeBack);
      this.closeOnboarding();
    },
    closeFromModal() {
      gtmPushEvent(gtmEvents.onboardingEscapeMod);
      this.closeOnboarding();
    },
    finish() {
      this.closeOnboarding();
    },
    startOnboarding() {
      this.switchStep(FREE_COURSE_ONBOARDING_STEPS.courseSchedule);
    },
    onStepButtonClick() {
      if (this.isOnboardingContactsStepActive) {
        this.switchStep(FREE_COURSE_ONBOARDING_STEPS.finishOnboarding);
        this.markOnboardingAsCompleted();
      }
    },
    applyListeners() {
      eventBus.$on(EVENT_BUS_KEYS.pageMounted, () => {
        if (!this.activeStepId) return;
        if (this.$route.name === 'course-content-webinar')
          this.switchStep('courseWebinars');
        if (this.$route.name === 'course-content-homework')
          this.switchStep('courseTask');
        if (
          this.$route.name === 'course-dashboard' &&
          this.isOnboardingCourseTaskStepActive
        )
          this.switchStep('courseContacts');
      });
      eventBus.$on(EVENT_BUS_KEYS.webinarPlay, () => {
        if (this.isOnboardingWebinarStepActive) this.removeWaveClasses();
      });
      eventBus.$on(EVENT_BUS_KEYS.webinarWatched, () => {
        if (!this.activeStepId) return;
        if (this.$route.name === 'course-content-webinar')
          this.swithcOnboardingToStreakReceivedStepOnReady = true;
      });
      eventBus.$on(EVENT_BUS_KEYS.streakPopoverShown, () => {
        if (!this.activeStepId) return;
        if (!this.swithcOnboardingToStreakReceivedStepOnReady) return;
        if (this.$route.name !== 'course-content-webinar') return;

        this.swithcOnboardingToStreakReceivedStepOnReady = false;
        setTimeout(() => {
          this.switchStep('courseStreaks');
        }, 100);
      });
      eventBus.$on(EVENT_BUS_KEYS.showTimelineMobileClick, () => {
        if (!this.activeStepId) return;
        if (this.$route.name === 'course-content-webinar')
          this.switchStep('courseHomeworksTimelineMobile');
      });
      eventBus.$on(EVENT_BUS_KEYS.homeworkCompleted, () => {
        if (!this.activeStepId) return;
        setTimeout(() => {
          if (this.$route.name === 'course-content-homework')
            this.$router.push({
              name: 'course-dashboard',
              params: { id: this.$route.params.id },
            });
        }, FREE_COURSE_ONBOARDING_STEP_HOMEWORK_DELAY);
      });
      eventBus.$on(EVENT_BUS_KEYS.streakPopoverClosed, () => {
        if (!this.activeStepId) return;
        if (this.$route.name === 'course-content-webinar')
          this.switchStep('courseHomeworks');
      });
    },
    onTooltipClose() {
      if (this.isOnboardingContactsStepActive) {
        this.onStepButtonClick();
        return;
      }
      this.showEscapeOnboardingModal();
    },
  },
};
</script>

<template>
  <div v-if="isOnboardingActive" :class="$style.root">
    <FreeCourseOnboardingModalIntro
      :visible="isIntroModalVisible && !isEscapeOnboardingModalVisible"
      :course="currentCourse"
      @skip="closeFromModal"
      @start="startOnboarding"
    />
    <FreeCourseOnboardingModalEscape
      :visible="isEscapeOnboardingModalVisible"
      @close="hideEscapeOnboardingModal"
      @leave="closeFromModal"
    />
    <FreeCourseOnboardingModalFinish
      :visible="isFinishModalVisible && !isEscapeOnboardingModalVisible"
      :course="currentCourse"
      @close="finish"
    />
    <FreeCourseOnboardingTooltip
      v-if="isTooltipVisible"
      :teacher-avatar-src="teacherAvatar"
      :active-step-key="activeStepId"
      @step-button-click="onStepButtonClick"
      @close="onTooltipClose"
    />
  </div>
</template>

<style lang="postcss" module>
.root {
  position: absolute;
  z-index: 3000000001;
}
</style>
