<script>
import { WIsland, WLoading } from '@WebiumTeam/ui_kit';
import { createNamespacedHelpers } from 'vuex';
import { FEATURE_TOGGLES } from '~/constants/feature-toggles';
import { SYSTEM_NOTIFICATION_SLUGS } from '~/constants/system-notifications';
import { TOOLTIPS } from '~/constants/tooltips';
import AttentionTooltip from '~/components/attention-tooltip.vue';
import ModalDelimiter from '~/components/modal-delimiter.vue';
import StreaksCalendarMini from '~/components/streaks/streaks-calendar-mini/streaks-calendar-mini.vue';
import StreaksCounter from '~/components/streaks/streaks-counter.vue';
import StreaksPairedInviteButton from '~/components/streaks/streaks-paired/streaks-paired-invite/streaks-paired-invite-button.vue';
import StreaksPairedRemoveButton from '~/components/streaks/streaks-paired/streaks-paired-remove/streaks-paired-remove-button.vue';
import StreaksStatisticsHeader from '~/components/streaks/streaks-statistics/streaks-statistics-header.vue';

const { mapState } = createNamespacedHelpers('streaks');
const { mapState: mapStreaksHistoryState } =
  createNamespacedHelpers('streaks-history');
const { mapGetters: mapFeatureTogglesGetters } =
  createNamespacedHelpers('feature-toggles');

export const messages = {
  ru: {
    counterLabel: 'мой стрик',
    pairedCounterLabel: 'парный стрик',
  },
};

export default {
  TOOLTIPS,
  i18n: {
    messages,
  },
  components: {
    ModalDelimiter,
    AttentionTooltip,
    WIsland,
    WLoading,
    StreaksCounter,
    StreaksStatisticsHeader,
    StreaksCalendarMini,
    StreaksPairedInviteButton,
    StreaksPairedRemoveButton,
  },
  props: {
    theme: {
      type: String,
      required: false,
      default: '',
    },
    hideMiniCalendar: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState([
      'currentStreak',
      'streakReceivedToday',
      'isLastInfoFetched',
      'buddyReceivedStreakToday',
      'currentPairedStreak',
      'buddy',
      'pairedStreakReceivedToday',
      'isLoading',
    ]),
    ...mapStreaksHistoryState({
      isStreaksHistoryLoading: 'isLoading',
      isStreaksHistoryFetched: 'isFetched',
    }),
    ...mapFeatureTogglesGetters(['isFeatureEnabled']),
    rootClasses() {
      return {
        [this.$style.root]: true,
        [this.$style[`_${this.theme}`]]: !!this.theme,
      };
    },
    showLoading() {
      return (
        (this.isStreaksHistoryLoading && !this.isStreaksHistoryFetched) ||
        !this.isLastInfoFetched ||
        this.isLoading
      );
    },
    isPairedStreaksEnabled() {
      return this.isFeatureEnabled(FEATURE_TOGGLES.pairedStreaks);
    },
    isPairedSteakSetUp() {
      return !!this.buddy;
    },
    actualBuddySystemNotification() {
      return this.isPairedSteakSetUp
        ? SYSTEM_NOTIFICATION_SLUGS.newBuddy
        : SYSTEM_NOTIFICATION_SLUGS.removedFromThePair;
    },
    buddySystemNotification() {
      if (this.isLoading) return false;
      return this.$systemNotifications.getSystemNotification(
        this.actualBuddySystemNotification
      );
    },
    streaksWrapperClasses() {
      return {
        [this.$style.streaksWrapper]: true,
        [this.$style._pairedStreakNotSetup]:
          this.isPairedStreaksEnabled && !this.isPairedSteakSetUp,
      };
    },
  },
  watch: {
    isLoading(value) {
      if (value) return;
      this.fetchSystemNotifications();
    },
  },
  mounted() {
    if (!this.$device.isSmallScreen) return;
    this.fetchSystemNotifications();
  },
  methods: {
    fetchSystemNotifications() {
      this.$systemNotifications.setSystemNotificationSlugsToDebouncedFetch([
        this.actualBuddySystemNotification,
      ]);
    },
  },
};
</script>

<template>
  <WIsland :class="rootClasses">
    <WLoading :show="showLoading" overlay />
    <div :class="$style.header">
      <StreaksStatisticsHeader />
    </div>
    <ModalDelimiter />
    <div :class="$style.content">
      <StreaksPairedRemoveButton
        v-if="isPairedSteakSetUp"
        :class="$style.pairRemoveButton"
      />
      <div :class="streaksWrapperClasses">
        <StreaksCounter
          data-testid="streaksCounter"
          :count="currentStreak"
          :is-active="streakReceivedToday"
          :label-text="$t('counterLabel')"
          show-label
          :size="$device.isSmallScreen ? 'xl' : 'l'"
        />
        <div v-if="isPairedStreaksEnabled">
          <StreaksCounter
            v-if="isPairedSteakSetUp"
            data-testid="pairedStreaksCounter"
            :class="$style.pairedStreakCounter"
            :count="currentPairedStreak"
            :is-active="pairedStreakReceivedToday"
            :label-text="$t('pairedCounterLabel')"
            show-label
            :size="$device.isSmallScreen ? 'xl' : 'l'"
            is-paired
          />
          <StreaksPairedInviteButton v-else />
          <AttentionTooltip
            v-if="buddySystemNotification"
            :popper-class="$style.attentionTooltip"
            :tooltip-key="actualBuddySystemNotification"
            :close-button-text="buddySystemNotification.buttonParams.label"
            :text="buddySystemNotification.text"
            :system-notification-slug="actualBuddySystemNotification"
            :over-modal="$device.isSmallScreen"
            hide-on-route-change
            disabled-auto-scroll
            placement="bottom"
            max-width="260px"
          />
        </div>
      </div>
      <StreaksCalendarMini v-if="!hideMiniCalendar" />
    </div>
  </WIsland>
</template>

<style lang="postcss" module>
.root {
  --island-padding: 0;

  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  font-size: 14px;
  line-height: 18px;
  color: var(--primary-500);
  text-align: center;
}

.pairRemoveButton {
  position: absolute;
  top: 4px;
  right: 4px;
  height: fit-content;
  visibility: hidden;
  opacity: 0;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 15px;
  justify-content: flex-start;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;

  &:hover {
    & .pairRemoveButton {
      visibility: visible;
      opacity: 1;
    }
  }
}

.streaksWrapper {
  display: flex;
  gap: 44px;
  align-items: flex-end;

  &._pairedStreakNotSetup {
    gap: 31px;
    padding-left: 12px;
  }

  & > div {
    min-width: 84px;
  }
}

._modal {
  &.root {
    border: none;
    box-shadow: none;
  }

  & .content {
    flex-direction: column;
    gap: 32px;
    padding: 28px;
  }

  & .header {
    padding: 0 0 12px;
    font-size: 16px;
    line-height: 20px;
  }
}

.pairedStreakCounter {
  padding: 0;
}

@media (--small-vp) {
  .pairRemoveButton {
    display: none;
  }

  .header {
    height: 38px;
    margin-top: 10px;
  }

  .streaksWrapper {
    gap: 24px;

    &._pairedStreakNotSetup {
      gap: 15px;
      padding-left: 7px;
    }

    & > div {
      min-width: 120px;
    }
  }

  .attentionTooltip {
    z-index: calc(var(--w-overlay-z-index) + 1);
  }
}
</style>
