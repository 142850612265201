// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".common-onboarding-modal__inner-pGLBq{position:relative;display:flex;flex-direction:column;height:100%}.common-onboarding-modal__header-c69fD{padding-bottom:24px}.common-onboarding-modal__body-9mSGn{padding:24px 0 0}.common-onboarding-modal__skipButton-8Y2Zn{position:absolute;top:calc(100% + 16px);right:0}.common-onboarding-modal__segmentedProgress-7F6KB{--segments-gap:12px;--label-font-size:16px;--label-line-height:20px;height:56px;padding:8px 0 12px}@media (max-width:1023px){.common-onboarding-modal__root-y2jTa{--w-drawer-height:calc(100% - 62px)}.common-onboarding-modal__segmentedProgress-7F6KB{--segments-gap:16px;--label-font-size:14px;--label-line-height:18px}.common-onboarding-modal__inner-pGLBq{max-width:100%;overflow:hidden}.common-onboarding-modal__header-c69fD{padding:12px 20px 8px}.common-onboarding-modal__body-9mSGn{padding:20px 20px 12px}.common-onboarding-modal__skipButton-8Y2Zn{position:fixed;top:13px;right:13px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inner": "common-onboarding-modal__inner-pGLBq",
	"header": "common-onboarding-modal__header-c69fD",
	"body": "common-onboarding-modal__body-9mSGn",
	"skipButton": "common-onboarding-modal__skipButton-8Y2Zn",
	"segmentedProgress": "common-onboarding-modal__segmentedProgress-7F6KB",
	"root": "common-onboarding-modal__root-y2jTa"
};
module.exports = ___CSS_LOADER_EXPORT___;
