// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".streaks-statistics__root-hmp42{--island-padding:0;position:relative;display:flex;flex-direction:column;flex-grow:1}.streaks-statistics__header-dJhwU{display:flex;align-items:center;justify-content:center;height:42px;font-size:14px;line-height:18px;color:var(--primary-500);text-align:center}.streaks-statistics__pairRemoveButton-DlnEK{position:absolute;top:4px;right:4px;height:-moz-fit-content;height:fit-content;visibility:hidden;opacity:0}.streaks-statistics__content-7z5qO{position:relative;display:flex;flex-direction:column;flex-grow:1;grid-gap:15px;gap:15px;justify-content:flex-start;align-items:center;padding-top:24px;padding-bottom:24px}.streaks-statistics__content-7z5qO:hover .streaks-statistics__pairRemoveButton-DlnEK{visibility:visible;opacity:1}.streaks-statistics__streaksWrapper-3v7ul{display:flex;grid-gap:44px;gap:44px;align-items:flex-end}.streaks-statistics__streaksWrapper-3v7ul.streaks-statistics___pairedStreakNotSetup-TI0up{grid-gap:31px;gap:31px;padding-left:12px}.streaks-statistics__streaksWrapper-3v7ul>div{min-width:84px}.streaks-statistics___modal-affJ0.streaks-statistics__root-hmp42{border:none;box-shadow:none}.streaks-statistics___modal-affJ0 .streaks-statistics__content-7z5qO{flex-direction:column;grid-gap:32px;gap:32px;padding:28px}.streaks-statistics___modal-affJ0 .streaks-statistics__header-dJhwU{padding:0 0 12px;font-size:16px;line-height:20px}.streaks-statistics__pairedStreakCounter-j0v93{padding:0}@media (max-width:1023px){.streaks-statistics__pairRemoveButton-DlnEK{display:none}.streaks-statistics__header-dJhwU{height:38px;margin-top:10px}.streaks-statistics__streaksWrapper-3v7ul{grid-gap:24px;gap:24px}.streaks-statistics__streaksWrapper-3v7ul.streaks-statistics___pairedStreakNotSetup-TI0up{grid-gap:15px;gap:15px;padding-left:7px}.streaks-statistics__streaksWrapper-3v7ul>div{min-width:120px}.streaks-statistics__attentionTooltip-GoCib{z-index:calc(var(--w-overlay-z-index) + 1)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "streaks-statistics__root-hmp42",
	"header": "streaks-statistics__header-dJhwU",
	"pairRemoveButton": "streaks-statistics__pairRemoveButton-DlnEK",
	"content": "streaks-statistics__content-7z5qO",
	"streaksWrapper": "streaks-statistics__streaksWrapper-3v7ul",
	"_pairedStreakNotSetup": "streaks-statistics___pairedStreakNotSetup-TI0up",
	"_modal": "streaks-statistics___modal-affJ0",
	"pairedStreakCounter": "streaks-statistics__pairedStreakCounter-j0v93",
	"attentionTooltip": "streaks-statistics__attentionTooltip-GoCib"
};
module.exports = ___CSS_LOADER_EXPORT___;
