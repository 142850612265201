<script>
import { WButton, WModal, WModalFullscreen } from '@WebiumTeam/ui_kit';
import { createNamespacedHelpers } from 'vuex';
import NotificationSettings from '~/components/notifications/notification-settings.vue';

const { mapState, mapActions } = createNamespacedHelpers(
  'notification-settings'
);

export const messages = {
  ru: {
    title: 'настройки уведомлений',
    cancel: 'Отмена',
    save: 'Сохранить',
  },
};

export default {
  i18n: {
    messages,
  },
  components: {
    WButton,
    NotificationSettings,
  },
  computed: {
    ...mapState(['modalVisible']),
    modalComponent() {
      return this.$device.isSmallScreen ? WModalFullscreen : WModal;
    },
  },
  methods: {
    ...mapActions(['closeSettingsModal']),
    onSave() {
      this.$refs.notificationSettings.save();
    },
  },
};
</script>

<template>
  <component
    :is="modalComponent"
    v-if="$auth.loggedIn"
    :visible="modalVisible"
    :title="$t('title')"
    fixed
    size="m"
    @close="closeSettingsModal"
  >
    <NotificationSettings
      ref="notificationSettings"
      @cancel="closeSettingsModal"
    />
    <template #footerContent>
      <div :class="$style.footer">
        <WButton
          :size="$device.isSmallScreen ? 'l' : 'm'"
          data-testid="cancel"
          theme="secondary"
          @click="closeSettingsModal"
        >
          {{ $t('cancel') }}
        </WButton>
        <WButton
          :size="$device.isSmallScreen ? 'l' : 'm'"
          data-testid="save"
          @click="onSave"
        >
          {{ $t('save') }}
        </WButton>
      </div>
    </template>
  </component>
</template>

<style lang="postcss" module>
.footer {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
}

@media (--small-vp) {
  .footer {
    justify-content: flex-start;
    height: fit-content;

    & > button {
      flex-grow: 1;
    }
  }
}
</style>
