// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".the-navbar__root-o7tRG{--padding-x:20px;position:sticky;top:0;left:0;z-index:var(--the-navbar-z-index);color:var(--primary-100);background-color:var(--primary-100);border-bottom:1px solid var(--grey-300)}.the-navbar__content-9jxJ3,.the-navbar__root-o7tRG{height:var(--navbar-height)}.the-navbar__content-9jxJ3{display:flex;align-items:center;justify-content:space-between;max-width:var(--max-content-width);padding:0 var(--padding-x);margin:0 auto}.the-navbar__logo-OUu5O{width:80px;margin-right:6.2%;padding:8px 0;color:var(--primary-500);transition:color var(--default-transition)}.the-navbar__logo-OUu5O .the-navbar__logoIcon-k64Y5{height:36px}@media (hover:hover){.the-navbar__logo-OUu5O:hover{color:var(--primary-200)}.the-navbar__logo-OUu5O:active{color:var(--primary-500)}}.the-navbar__nav-R\\+e7y{display:flex;grid-gap:36px;gap:36px;align-items:center}.the-navbar__icon-fJi4g{flex-shrink:0;align-self:center;font-size:24px}.the-navbar__luringDot-oA3Cx{position:absolute;margin-left:20px;margin-top:-6px}@media (max-width:1023px){.the-navbar__nav-R\\+e7y{grid-gap:0;gap:0;margin-right:calc(var(--padding-x)*-1)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "the-navbar__root-o7tRG",
	"content": "the-navbar__content-9jxJ3",
	"logo": "the-navbar__logo-OUu5O",
	"logoIcon": "the-navbar__logoIcon-k64Y5",
	"nav": "the-navbar__nav-R+e7y",
	"icon": "the-navbar__icon-fJi4g",
	"luringDot": "the-navbar__luringDot-oA3Cx"
};
module.exports = ___CSS_LOADER_EXPORT___;
