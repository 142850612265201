<script>
import { WButton, WModal, WModalFullscreen } from '@WebiumTeam/ui_kit';

export const messages = {
  ru: {
    helloText: 'привет, это {name} 🤗',
    question:
      'хочешь узнать, как тут всё устроено? <br>это займёт не больше 15 минут!',
    noComeBack: 'вернуться к экскурсии позже не получится',
    skip: 'не буду проходить',
    go: 'на экскурсию!',
  },
};
export default {
  i18n: { messages },
  components: {
    WButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    course: {
      type: Object,
      required: true,
    },
  },
  computed: {
    modalComponent() {
      return this.$device.isSmallScreen ? WModalFullscreen : WModal;
    },
    teacherName() {
      return this.course.teachers[0].fullName;
    },
    teacherAvatar() {
      return this.course.teachers[0].avatar;
    },
    topicName() {
      return this.course.topic.name;
    },
  },
};
</script>

<template>
  <component
    :is="modalComponent"
    :visible="visible"
    hide-close
    disable-overlay-close-event
    is-overlay-transparent
    @close="$emit('close')"
  >
    <div :class="$style.wrapper">
      <div :class="$style.image">
        <img
          :class="$style.background"
          :src="require('./images/img-modal-intro-background.svg')"
          width="336"
          height="205"
          alt=""
        />
        <img
          :class="$style.avatar"
          :src="teacherAvatar"
          width="82"
          height="82"
          alt=""
          data-testid="teacher-avatar"
        />
      </div>
      <p
        :class="$style.text"
        data-testid="teacher-text"
        v-html="$t('helloText', { name: teacherName, topic: topicName })"
      ></p>
      <p :class="$style.text" v-html="$t('question')"></p>
      <p :class="$style.text" v-html="$t('noComeBack')"></p>
    </div>
    <template #footerContent>
      <div :class="$style.footer">
        <WButton
          theme="secondary"
          :size="$device.isSmallScreen ? 'l' : 'm'"
          data-testid="skip-button"
          @click="$emit('skip')"
        >
          {{ $t('skip') }}
        </WButton>
        <WButton
          :size="$device.isSmallScreen ? 'l' : 'm'"
          data-testid="go-button"
          @click="$emit('start')"
          >{{ $t('go') }}
        </WButton>
      </div>
    </template>
  </component>
</template>

<style lang="postcss" module>
.wrapper {
  display: flex;
  flex-direction: column;
}

.image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 16px;
}

.background {
  width: 100%;
  height: auto;
  border-radius: 12px;
}

.avatar {
  position: absolute;
  top: 14px;
  left: 50%;
  transform: translateX(-50%);
}

.text {
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.32px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &:nth-of-type(2) {
    margin-bottom: 16px;
  }
}

.footer {
  display: flex;
  gap: 16px;
  width: 100%;

  & > button {
    flex: 1 1 0;
  }
}

@media (--small-vp) {
  .text {
    &:nth-of-type(2) {
      margin-bottom: 8px;
    }
  }

  .footer {
    flex-direction: column-reverse;
    gap: 12px;
  }
}
</style>
