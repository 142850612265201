// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".student-greeting-survey-modal__content-JcZsx{display:flex;flex-direction:column;align-items:center;justify-content:center}.student-greeting-survey-modal__form-DXu8m{width:100%}.student-greeting-survey-modal__item-E\\+shR{position:relative;display:flex;flex-direction:column;grid-gap:20px;gap:20px;align-items:flex-start;padding:25px 0}.student-greeting-survey-modal__item-E\\+shR:first-child{padding-top:0}.student-greeting-survey-modal__item-E\\+shR:last-child{padding-bottom:0}.student-greeting-survey-modal__item-E\\+shR:last-child:after{display:none}.student-greeting-survey-modal__description-3vvqb{font-size:24px;line-height:28px}.student-greeting-survey-modal__answers-5iaSG{--radiobutton-align-items:center;display:flex;flex-direction:column;align-items:flex-start;padding-top:2px}.student-greeting-survey-modal__radioItem-BIDtv:not(:last-child){margin-bottom:16px}.student-greeting-survey-modal__footer-BkH7j{display:flex;grid-gap:16px;gap:16px;justify-content:flex-end}.student-greeting-survey-modal__popoverMenu-F33on{width:248px;padding:14px 14px 18px}.student-greeting-survey-modal__popoverTitle-T7hlb{margin-bottom:18px;font-size:18px;line-height:22px}@media (max-width:1023px){.student-greeting-survey-modal__form-DXu8m{width:100%;padding:0 var(--modal-side-padding)}.student-greeting-survey-modal__content-JcZsx{justify-content:flex-start;height:100%;overflow-x:visible;overflow-y:clip}.student-greeting-survey-modal__item-E\\+shR{flex-direction:column;grid-gap:16px;gap:16px;padding:20px 0}.student-greeting-survey-modal__description-3vvqb{font-size:20px;line-height:24px}.student-greeting-survey-modal__answers-5iaSG{display:inline-flex}.student-greeting-survey-modal__popoverMenu-F33on{width:248px}.student-greeting-survey-modal__footer-BkH7j{display:flex;justify-content:flex-end}.student-greeting-survey-modal__footer-BkH7j button{width:calc(50% - 8px)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "student-greeting-survey-modal__content-JcZsx",
	"form": "student-greeting-survey-modal__form-DXu8m",
	"item": "student-greeting-survey-modal__item-E+shR",
	"description": "student-greeting-survey-modal__description-3vvqb",
	"answers": "student-greeting-survey-modal__answers-5iaSG",
	"radioItem": "student-greeting-survey-modal__radioItem-BIDtv",
	"footer": "student-greeting-survey-modal__footer-BkH7j",
	"popoverMenu": "student-greeting-survey-modal__popoverMenu-F33on",
	"popoverTitle": "student-greeting-survey-modal__popoverTitle-T7hlb"
};
module.exports = ___CSS_LOADER_EXPORT___;
