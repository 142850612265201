<template>
  <div :class="$style.root">
    <IconsSprite />
    <TheNavbar :class="$style.header" />
    <div :id="wrapperId" :class="$style.wrapper">
      <aside v-if="!$device.isSmallScreen" :class="$style.sidebar">
        <SideBarMenu />
        <CommonOnboardingReminder :class="$style.desktopOnboardingReminder" />
        <BannerSidebar :class="$style.banner" sidebar />
      </aside>
      <main :id="mainId" :class="$style.main">
        <CommonOnboardingReminder
          v-if="isMobileOnboardingReminderVisible"
          :class="$style.mobileOnboardingReminder"
        />
        <Nuxt />
      </main>
    </div>
    <NpsManager />
    <CourseOnboardingModalManager />
    <CommonOnboardingModalManager />
    <NotificationSettingsModal />
    <MotivationNewLevelMessage />
    <MotivationNewAchievementMessages />
    <TelegramLinkBanner />
    <PortalTarget
      :class="$style.toastMessagesContainer"
      name="toast-messages"
      multiple
    ></PortalTarget>
    <FreeCourseOnboardingManager v-if="isFreeCourseOnboardingShouldBeShown" />
  </div>
</template>

<script>
import { ROUTE_META_KEYS } from '~/routing/routes';
import { createNamespacedHelpers } from 'vuex';
import { DOM_ELEMENT_SELECTORS } from '~/constants/dom-elements';
import { EVENT_BUS_KEYS } from '~/constants/event-bus';
import { GROWTHBOOK_BSK_ONBOARDING_AA_TEST_ID } from '~/constants/growthbook';
import { COURSE_ONBOARDING_TYPES } from '~/constants/onboarding';
import { LAST_ACTIVE_STEP_ID_SESSION_KEY } from '~/constants/session-storage';
import { trackAnalyticsEvents } from '~/api/analytics';
import { getIsOnboardingCouldBeShown } from '~/api/student-courses';
import { handleError } from '~/services/error-handling/error-handling';
import { eventBus } from '~/services/event-bus';
import { getSessionStorageItem } from '~/services/session-storage';
import BannerSidebar from '~/components/banner-sidebar.vue';
import CommonOnboardingModalManager from '~/components/common-onboarding/common-onboarding-modal-manager.vue';
import CommonOnboardingReminder from '~/components/common-onboarding/common-onboarding-reminder.vue';
import CourseOnboardingModalManager from '~/components/course-onboarding-modal/course-onboarding-modal-manager.vue';
import FreeCourseOnboardingManager from '~/components/free-course-onboarding/free-course-onboarding-manager.vue';
import IconsSprite from '~/components/icon/icons-sprite.vue';
import MotivationNewAchievementMessages from '~/components/motivation/motivation-new-achievement-messages.vue';
import MotivationNewLevelMessage from '~/components/motivation/motivation-new-level-message/motivation-new-level-message.vue';
import NotificationSettingsModal from '~/components/notifications/notification-settings-modal.vue';
import NpsManager from '~/components/nps/nps-manager.vue';
import SideBarMenu from '~/components/side-bar-menu.vue';
import TelegramLinkBanner from '~/components/telegram-link-banner/telegram-link-banner.vue';
import TheNavbar from '~/components/the-navbar/the-navbar.vue';

const { mapActions: mapBannersActions } = createNamespacedHelpers('banners');
const { mapState: mapCurrentCourseState, mapActions: mapCurrentCourseActions } =
  createNamespacedHelpers('current-course');

export default {
  components: {
    CommonOnboardingModalManager,
    SideBarMenu,
    IconsSprite,
    TheNavbar,
    CommonOnboardingReminder,
    BannerSidebar,
    NotificationSettingsModal,
    NpsManager,
    MotivationNewLevelMessage,
    CourseOnboardingModalManager,
    MotivationNewAchievementMessages,
    TelegramLinkBanner,
    FreeCourseOnboardingManager,
  },
  data() {
    return {
      isFreeCourseOnboardingEnabledForUser: undefined,
      freeCourseOnboardingActiveStepFromSession: getSessionStorageItem(
        LAST_ACTIVE_STEP_ID_SESSION_KEY
      ),
    };
  },
  computed: {
    ...mapCurrentCourseState(['currentCourse']),
    isFreeCourseOnboardingShouldBeShown() {
      if (!this.isFreeCourseOnboardingPage) return false;
      return (
        this.isFreeCourseOnboardingEnabledForUser ||
        this.freeCourseOnboardingActiveStepFromSession
      );
    },
    isMobileOnboardingReminderVisible() {
      return (
        this.$device.isSmallScreen &&
        !!this.$route.meta[ROUTE_META_KEYS.useCommonOnboarding]
      );
    },
    wrapperId() {
      return DOM_ELEMENT_SELECTORS.innerLayoutWrapperId;
    },
    mainId() {
      return DOM_ELEMENT_SELECTORS.innerLayoutMainId;
    },
    isFreeCourseOnboardingPage() {
      return !!this.$route.meta?.initFreeCourseOnboarding;
    },
    isFreeOnboardingEnabledOnCurrentCourse() {
      return (
        this.currentCourse &&
        this.currentCourse.onboardingConfig?.onboardingType ===
          COURSE_ONBOARDING_TYPES.excursion
      );
    },
  },
  watch: {
    '$route.params': {
      immediate: true,
      handler(newParams, oldParams) {
        const paramsDataNotChanged =
          oldParams &&
          Object.keys(oldParams).length === 0 &&
          newParams &&
          Object.keys(newParams).length === 0;
        if (paramsDataNotChanged) return;
        this.fetchBanners({ courseId: newParams?.id });
      },
    },
    $route() {
      this.$nextTick(() => {
        this.handleFreeCourseOnboardingTest();
      });
    },
  },
  mounted() {
    this.handleFreeCourseOnboardingTest();
  },
  methods: {
    ...mapCurrentCourseActions(['updateCourseOnboardingType']),
    ...mapBannersActions(['fetchBanners']),
    async trackFreeCourseOnboarding(userTestGroup, aaTestKey) {
      const baseEventName = 'experiment_viewed';
      const eventName =
        this.$config.servicesEnvironment === 'production'
          ? baseEventName
          : `${baseEventName}.dev`;
      await trackAnalyticsEvents({
        id: this.$config.analyticsWebhooksIds.openStudentHintEvent,
        events: [
          {
            eventName,
            experimentId: aaTestKey,
            userGroup: userTestGroup,
          },
        ],
        isSingleEvent: true,
      });
    },
    async handleFreeCourseOnboardingTest() {
      if (
        !this.isFreeCourseOnboardingPage ||
        !this.isFreeOnboardingEnabledOnCurrentCourse
      ) {
        this.isFreeCourseOnboardingEnabledForUser = false;
        this.freeCourseOnboardingActiveStepFromSession = undefined;
        eventBus.$emit(EVENT_BUS_KEYS.freeCourseOnboardingABTestHappend);
        return;
      }

      let isFreeCourseOnboardingCouldBeShown;

      try {
        const { couldBeShown } = await getIsOnboardingCouldBeShown(
          this.currentCourse.id
        );
        isFreeCourseOnboardingCouldBeShown = couldBeShown;
      } catch (error) {
        handleError(error);
      }

      if (!isFreeCourseOnboardingCouldBeShown) {
        eventBus.$emit(EVENT_BUS_KEYS.freeCourseOnboardingABTestHappend);
        return;
      }

      const userPublicId = this.$auth.user.publicId;
      const growthBook = await this.$growthBook({
        publicId: userPublicId,
        hasFreeCourseOnboarding: true,
      });
      if (!growthBook) {
        eventBus.$emit(EVENT_BUS_KEYS.freeCourseOnboardingABTestHappend);
        return;
      }
      this.isFreeCourseOnboardingEnabledForUser = growthBook.isOn(
        GROWTHBOOK_BSK_ONBOARDING_AA_TEST_ID
      );
      if (!this.isFreeCourseOnboardingEnabledForUser)
        this.activateWelcomeOnboarding();
      const userGroup =
        this.isFreeCourseOnboardingEnabledForUser === true ? 1 : 0;
      const aaTestKey =
        growthBook.context.features[GROWTHBOOK_BSK_ONBOARDING_AA_TEST_ID]
          .rules[0].key;
      this.trackFreeCourseOnboarding(userGroup, aaTestKey);
      this.$nextTick(() => {
        eventBus.$emit(EVENT_BUS_KEYS.freeCourseOnboardingABTestHappend);
      });
    },
    activateWelcomeOnboarding() {
      this.updateCourseOnboardingType(COURSE_ONBOARDING_TYPES.minimal);
    },
  },
};
</script>

<style lang="postcss" module>
.root {
  --sidebar-width: 192px;
  --inner-gap: 54px;

  display: flex;
  flex-direction: column;
}

.wrapper {
  display: flex;
  gap: var(--inner-gap);
  width: var(--max-content-width);
  max-width: 100%;
  padding: 0 var(--page-padding);
  margin: var(--navbar-margin) auto 0 auto;
}

.sidebar {
  --gap: 32px;

  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  width: var(--sidebar-width);
  padding-bottom: var(--gap);
}

.banner {
  margin-top: 24px;
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: calc(100% - var(--sidebar-width) - var(--inner-gap));
  min-height: var(--main-content-min-height);
  padding-bottom: 80px;
}

.desktopOnboardingReminder {
  margin-top: 16px;
}

.mobileOnboardingReminder {
  margin-bottom: 12px;
}

.toastMessagesContainer {
  position: fixed;
  right: 24px;
  bottom: 24px;
  z-index: var(--base-toast-message-z-index);
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-end;
  justify-content: flex-end;
}

@media (--large-vp) {
  .root {
    --inner-gap: 36px;
  }
}

@media (--small-vp) {
  .root {
    --navbar-margin: 12px;
    --sidebar-width: 0;
    --inner-gap: 0;
  }

  .sidebar {
    display: none;
  }

  .main {
    width: 100%;
  }

  .toastMessagesContainer {
    right: 12px;
    bottom: initial;
    left: 12px;
    top: 24px;
    gap: 8px;
  }
}
</style>
