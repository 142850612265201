import cloneDeep from 'lodash/cloneDeep';
import { COURSE_ONBOARDING_TYPES } from '~/constants/onboarding';
import {
  getCourse,
  getCoursePreview,
  getCourseProgress,
  setLearningGoal,
} from '~/api/student-courses';
import { changeMentor } from '~/api/surveys';
import { getCourseMainMentor } from '~/services/course';
import { handleError } from '~/services/error-handling/error-handling';
import { getLSItem, setLSItem } from '~/services/local-storage';

const mentorChangeRequestAwaitLimitInMS = 24 * 60 * 60 * 1000;

export const state = () => ({
  currentCourse: undefined,
  currentCourseId: undefined,
  currentCoursePreview: undefined,
  currentCourseProgress: undefined,
  mentorChangeRequestedAt: false,
});

export const mutations = {
  RESET_COURSE_PROGRESS(state) {
    state.currentCourseProgress = undefined;
  },
  SET_COURSE_PROGRESS(state, courseProgress) {
    state.currentCourseProgress = courseProgress;
  },
  SET_COURSE(state, course) {
    state.currentCourse = course;
  },
  SET_COURSE_PREVIEW(state, coursePreview) {
    state.currentCoursePreview = coursePreview;
  },
  SET_CURRENT_COURSE_ID(state, courseId) {
    state.currentCourseId = courseId;
  },
  SET_MENTOR_CHANGE_REQUESTED_AT(state, value) {
    if (!value) state.mentorChangeRequestedAt = undefined;
    state.mentorChangeRequestedAt = +value;
  },
};

export const actions = {
  setCurrentCourseId({ state, commit }, courseId) {
    commit('SET_CURRENT_COURSE_ID', courseId);
    if (state.currentCourse?.id !== courseId) commit('SET_COURSE', undefined);
    if (state.currentCoursePreview?.id !== courseId)
      commit('SET_COURSE_PREVIEW', undefined);
  },
  async loadCoursePreview({ state, commit, dispatch }, courseId) {
    if (
      state.currentCoursePreview &&
      state.currentCoursePreview.id === +courseId
    )
      return;
    const coursePreview = await getCoursePreview({ courseId });
    dispatch('setCurrentCourseId', courseId);
    commit('SET_COURSE_PREVIEW', coursePreview);
    if (state.currentCourse?.id !== courseId) commit('SET_COURSE', undefined);
  },
  async loadCourseById({ state, commit, dispatch }, courseId) {
    if (state.currentCourse && state.currentCourse.id === +courseId) return;

    const course = await getCourse({ courseId });
    dispatch('setCurrentCourseId', courseId);
    commit('RESET_COURSE_PROGRESS');
    commit('SET_COURSE', course);
    dispatch('resolveMentorChangeRequestedAt');
    if (state.currentCoursePreview?.id !== courseId)
      commit('SET_COURSE_PREVIEW', undefined);
  },
  async refetchCurrentCourse({ state, commit }) {
    if (!state.currentCourse)
      throw new Error('Tried to refetch undefined course');

    const course = await getCourse({ courseId: state.currentCourse.id });
    commit('SET_COURSE', course);
  },
  async loadCurrentCourseProgress({ state, commit }) {
    if (!state.currentCourseId)
      throw new Error('Tried to load progress for undefined course');

    const courseProgress = await getCourseProgress({
      courseId: state.currentCourseId,
    });
    commit('SET_COURSE_PROGRESS', courseProgress);
  },
  async setCourseLearningGoal(context, goalData) {
    await setLearningGoal(goalData);
  },
  async updateCurrentCourseLearningGoal(
    { state, dispatch },
    { goal, comment }
  ) {
    if (!state.currentCourse)
      throw new Error('Tried to update learning goal for undefined course');

    await dispatch('setCourseLearningGoal', {
      courseId: state.currentCourse.id,
      goal,
      comment,
    });
    await dispatch('refetchCurrentCourse');
  },
  async requestMentorChange(
    { state, getters, dispatch },
    { changeReason, newMentorWishes } = {}
  ) {
    try {
      await changeMentor({
        courseId: state.currentCourse.id,
        mentorId: getters.currentCourseMentor.id,
        changeReason,
        newMentorWishes,
      });
      dispatch('keepMentorChangeRequestedAt', Date.now());
    } catch (error) {
      handleError(error);
    }
  },
  keepMentorChangeRequestedAt({ commit, getters }, value) {
    commit('SET_MENTOR_CHANGE_REQUESTED_AT', value);
    setLSItem(getters.mentorChangeRequestedAtLSKey, value);
  },
  resolveMentorChangeRequestedAt({ commit, getters }) {
    commit(
      'SET_MENTOR_CHANGE_REQUESTED_AT',
      getLSItem(getters.mentorChangeRequestedAtLSKey)
    );
  },
  updateCourseOnboardingType({ state, commit }, onboardingType) {
    if (!Object.values(COURSE_ONBOARDING_TYPES).includes(onboardingType))
      return;
    const course = cloneDeep(state.currentCourse);
    if (!course?.onboardingConfig?.onboardingType) return;
    course.onboardingConfig.onboardingType = onboardingType;
    commit('SET_COURSE', course);
  },
};

export const getters = {
  currentCourseMentor: (state) => {
    if (!state.currentCourse?.isConfigured) return undefined;
    if (!state.currentCourse.studyGroup?.mentors) return undefined;
    return getCourseMainMentor(state.currentCourse);
  },
  isWorkoutAvailable: (state, getters) =>
    getters.isThemesWorkoutAvailable || getters.isKimNumbersWorkoutAvailable,
  isThemesWorkoutAvailable: (state) =>
    state.currentCourse.areProgressThemesExists &&
    state.currentCourse.themesProgressEnabled,
  isKimNumbersWorkoutAvailable: (state) =>
    state.currentCourse.areProgressTopicKimNumbersExists &&
    state.currentCourse.kimProgressEnabled,
  mentorChangeRequestedAtLSKey: (state, getters) => {
    if (!state.currentCourse) return undefined;
    const courseId = state.currentCourse.id;
    if (!getters.currentCourseMentor) return undefined;
    const mentorId = getters.currentCourseMentor.id;
    return `mentor_change_${courseId}_${mentorId}_requested_at`;
  },
  isAwaitingMentorChange: (state) =>
    !!state.mentorChangeRequestedAt &&
    Date.now() - state.mentorChangeRequestedAt <
      mentorChangeRequestAwaitLimitInMS,
};
