import { render, staticRenderFns } from "./motivation-new-level-message.vue?vue&type=template&id=2468ec1f&"
import script from "./motivation-new-level-message.vue?vue&type=script&lang=js&"
export * from "./motivation-new-level-message.vue?vue&type=script&lang=js&"
import style0 from "./motivation-new-level-message.vue?vue&type=style&index=0&lang=postcss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports