// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notification-settings-row__root-SyPic{grid-gap:8px;gap:8px;width:100%}.notification-settings-row__infoWrapper-8UzO9,.notification-settings-row__root-SyPic{display:flex;flex-direction:column}.notification-settings-row__name-xGGpV{margin-bottom:4px;font-size:16px;font-weight:400;line-height:20px}.notification-settings-row__description-w3WbJ{font-size:14px;line-height:18px;color:var(--grey-600)}.notification-settings-row__content-MG1mG{display:flex;flex-shrink:0;grid-gap:38px;gap:38px;width:100%}@media (max-width:1023px){.notification-settings-row__root-SyPic{align-items:flex-start}.notification-settings-row__content-MG1mG{grid-gap:24px;gap:24px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "notification-settings-row__root-SyPic",
	"infoWrapper": "notification-settings-row__infoWrapper-8UzO9",
	"name": "notification-settings-row__name-xGGpV",
	"description": "notification-settings-row__description-w3WbJ",
	"content": "notification-settings-row__content-MG1mG"
};
module.exports = ___CSS_LOADER_EXPORT___;
