import { getSurveysOverview } from '~/api/surveys';
import { isFreeCourseOnboardingActive } from '~/utils/free-course-onboarding';
import { diffInDays, getCurrentDate } from '~/services/datetime';

export const MAX_CLOSE_COUNT = 3;

export const state = () => ({
  surveys: undefined,
  studentGreetingSurveyModalVisible: false,
});

export const mutations = {
  SET_MODAL_VISIBILITY(state, value) {
    state.studentGreetingSurveyModalVisible = !!value;
  },
  SET_SURVEYS(state, value) {
    state.surveys = value;
  },
};

export const actions = {
  async loadSurveys({ state, commit, rootState }) {
    if (state.surveys) return;
    if (!rootState.auth?.loggedIn) return;
    const surveys = await getSurveysOverview();

    commit('SET_SURVEYS', surveys);
  },
  async openStudentGreetingSurveyModalIfShouldBeVisible({ dispatch, getters }) {
    await dispatch('loadSurveys');

    if (getters.isModalShouldBeVisible)
      dispatch('openStudentGreetingSurveyModal');
  },
  openStudentGreetingSurveyModal({ commit }) {
    commit('SET_MODAL_VISIBILITY', true);
  },
  closeStudentGreetingSurveyModal({ commit }) {
    commit('SET_MODAL_VISIBILITY', false);
  },
};

export const getters = {
  isModalShouldBeVisible(state, getters, rootState) {
    const isHideAllPopups = isFreeCourseOnboardingActive();
    if (isHideAllPopups) return false;
    if (rootState['mentors-satisfaction-modal'].mentorsSatisfactionModalVisible)
      return false;
    if (!state.surveys) return false;
    if (state.surveys.wasAnswered) return false;
    if (state.surveys.closedCount >= MAX_CLOSE_COUNT) return false;
    if (!state.surveys.lastClosedAt) return true;
    return diffInDays(getCurrentDate(), state.surveys.lastClosedAt);
  },
};
