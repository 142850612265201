<script>
import { WIconButton, WIsland } from '@WebiumTeam/ui_kit';
import AudioRecorder from 'simple-audio-recorder';
import { createNamespacedHelpers } from 'vuex';
import { MODALS } from '~/constants/modals';
import { durationSecondsToHumanTime } from '~/utils/time';
import BaseConfirmationModal from '~/components/_base/_base-confirmation-modal.vue';

const { mapState: mapModalsState, mapActions: mapModalsActions } =
  createNamespacedHelpers('modals');
const maxVoiceMessageLengthInSeconds = 60 * 20;

export const messages = {
  ru: {
    confirmationTitle: 'завершить запись',
    confirmationOkText: 'завершить',
    confirmationDescription:
      'ты действительно хочешь завершить запись и отменить голосовое сообщение?',
    cancel: 'отменить',
    done: 'готово',
  },
};

export default {
  MODALS,
  i18n: { messages },
  components: {
    WIsland,
    WIconButton,
    BaseConfirmationModal,
  },
  data() {
    return {
      recorder: undefined,
      isShown: false,
      audioDuration: 0,
      saveOnNextStop: true,
      durationInterval: undefined,
    };
  },
  computed: {
    ...mapModalsState(['visibleModalName']),
    humanAudioDuration() {
      return durationSecondsToHumanTime(this.audioDuration);
    },
    isConfirmationModalVisible() {
      return this.visibleModalName === MODALS.confirmation;
    },
  },
  beforeDestroy() {
    this.clearDurationInterval();
  },
  mounted() {
    AudioRecorder.preload(`${window.location.origin}/scripts/mp3worker.js`);
    this.recorder = new AudioRecorder({ encoderBitRate: 24 });
  },
  methods: {
    ...mapModalsActions([
      'closeModal',
      'setVisibleModalNameIfPriorityIsHigher',
    ]),
    clearDurationInterval() {
      clearInterval(this.durationInterval);
      this.durationInterval = undefined;
      this.$emit('stop');
    },
    show() {
      this.isShown = true;
    },
    hide() {
      this.isShown = false;
    },
    onCancel() {
      this.setVisibleModalNameIfPriorityIsHigher(MODALS.confirmation);
    },
    startRecord() {
      this.show();
      this.recorder.start().then(() => {
        this.durationInterval = setInterval(() => {
          this.audioDuration += 1;
          if (this.audioDuration >= maxVoiceMessageLengthInSeconds)
            this.stopRecord();
        }, 1000);
        this.$emit('start');
      });
    },
    stopRecord() {
      this.recorder.stop().then((mp3Blob) => {
        this.clearDurationInterval();
        if (this.saveOnNextStop) {
          const currentTime = new Date().toLocaleTimeString('en-GB');
          const voiceMessageFile = new File(
            [mp3Blob],
            `voice-message_${currentTime}.mp3`
          );
          this.$emit('record', [voiceMessageFile]);
        }
        this.hide();
        this.audioDuration = 0;
        this.saveOnNextStop = true;
        if (this.isConfirmationModalVisible) this.closeModal();
      });
    },
    cancelRecord() {
      this.saveOnNextStop = false;
      this.stopRecord();
      this.closeModal();
    },
    onError() {
      this.$emit('stop');
    },
  },
};
</script>

<template>
  <WIsland v-if="isShown" v-click-outside="onCancel" :class="$style.root">
    <WIconButton
      :alt="$t('cancel')"
      theme="emptyLight"
      :size="$device.isSmallScreen ? 'l' : 'm'"
      icon-name="close-S"
      @click="onCancel"
    />
    <div :class="$style.recIndicator"></div>
    <div :class="$style.duration">{{ humanAudioDuration }}</div>
    <WIconButton
      ref="stop"
      :alt="$t('done')"
      theme="emptyLight"
      :size="$device.isSmallScreen ? 'l' : 'm'"
      icon-name="check-black"
      @click="stopRecord"
    />
    <BaseConfirmationModal
      :visible="isConfirmationModalVisible"
      :title="$t('confirmationTitle')"
      :description="$t('confirmationDescription')"
      :confirmation-text="$t('confirmationOkText')"
      @click.native.stop
      @ok="cancelRecord"
      @close="closeModal"
    />
  </WIsland>
</template>

<style lang="postcss" module>
.root {
  --island-padding: 2px;
  --button-align-items: center;

  display: flex;
  align-items: center;
  border: 2px solid var(--primary-500);
  box-shadow: var(--dropdown-shadow);
}

.recIndicator {
  width: 8px;
  height: 8px;
  margin: 0 6px;
  background-color: var(--danger-500);
  border-radius: 100%;
  animation-name: pulse;
  animation-duration: 1600ms;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
}

.duration {
  min-width: 38px;
  margin-right: 8px;
  letter-spacing: 0.02em;
}

.attachmentButton {
  font-size: 20px;
}

@keyframes pulse {
  0% {
    background-color: var(--danger-500);
  }

  80% {
    background-color: var(--grey-300);
  }

  100% {
    background-color: var(--danger-500);
  }
}
</style>
