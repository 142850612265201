<script>
import { WButton, WModal, WModalSheet } from '@WebiumTeam/ui_kit';

export const messages = {
  ru: {
    title: 'подтверждение',
    description: 'вы уверены?',
    cancel: 'отмена',
    ok: 'да',
  },
};

export default {
  i18n: { messages },
  components: {
    WButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default() {
        return this.$t('title');
      },
    },
    description: {
      type: String,
      default() {
        return this.$t('description');
      },
    },
    confirmationText: {
      type: String,
      default() {
        return this.$t('ok');
      },
    },
  },
  computed: {
    modalComponent() {
      return this.$device.isSmallScreen ? WModalSheet : WModal;
    },
  },
};
</script>

<template>
  <component
    :is="modalComponent"
    :visible="visible"
    :class="$style.root"
    :title="title"
    @close="$emit('close')"
  >
    <div :class="$style.wrapper">
      <div>
        {{ description }}
      </div>
    </div>
    <template #footerContent>
      <div :class="$style.footer">
        <WButton
          theme="secondary"
          :size="$device.isSmallScreen ? 'l' : 'm'"
          data-testid="cancel-button"
          @click="$emit('close')"
        >
          {{ $t('cancel') }}
        </WButton>
        <WButton
          :size="$device.isSmallScreen ? 'l' : 'm'"
          data-testid="ok-button"
          @click="$emit('ok')"
          >{{ confirmationText }}
        </WButton>
      </div>
    </template>
  </component>
</template>

<style lang="postcss" module>
.root {
  color: var(--text-main-color);
}

.wrapper {
  font-size: 16px;
  line-height: 20px;
}

.footer {
  display: flex;
  gap: 16px;
  justify-content: flex-end;
}

@media (--small-vp) {
  .footer {
    flex-wrap: wrap-reverse;

    & > * {
      flex-grow: 1;
    }
  }
}
</style>
